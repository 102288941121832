import {connect} from 'pwa-helpers';
import {LitElement, html} from 'lit';
import {customElement} from 'lit/decorators/custom-element.js';
import {when} from 'lit/directives/when.js';
import {classMap} from 'lit/directives/class-map.js';

import {msg} from '@lit/localize';
import {navigator, router} from 'lit-element-router';
import {repeat} from 'lit/directives/repeat.js';
import deepEqual from 'deep-equal';

import store from '../../store';
import {routeUrl} from '../../util/routeUrl';
import getRoutes from '../../routes';
import style from '../../../../scss/accountDetail.scss';
import formPageStyle from '../../../../scss/formPage.scss';
import linkstyle from '../../../../scss/link.scss';
import loadingSpinnerStyle from '../../../../scss/loadingSpinner.scss';
import arrowRight from '../../icons/arrowRight';

import {
	createAdmin, createOperator,
	createUser, fetchAccount,
	selectAccountById, updateAccount
} from '../../slices/accountSlice';
import {deepClone} from '../../util/deepClone';
import {selectProfileById} from '../../slices/profileSlice';
import {navigateTo} from '../../slices/statusSlice';
import {trimObject} from '../../util/trimObject';

@customElement('arc-create-account')
export class CreateAccount extends connect(store)(router(navigator(LitElement))) {
	static styles = [style, formPageStyle, linkstyle, loadingSpinnerStyle];

	static properties = {
		localFormData: {type: Object, hasChanged: (n, o) => !deepEqual(n, o)},
		errors: {type: Object, hasChanged: (n, o) => !deepEqual(n, o)},
		edit: {type: Boolean}
	};

	constructor() {
		super();
		this.updateNeeded = false;
		this.initialData = {
			role: 'user',
			email: '',
			name: '',
			isEnabled: 1,
			isTest: 0,
			withProfile: 1,
			isGroup: 0,
			defaultLang: 'de',
			singleInfo: {
				firstName: '',
				lastName: '',
				penName: ''
			},
			groupInfo: {
				name: ''
			}
		};
		this.errors = null;
		this.currentLanguage = 'en';
		this.create = false;

		store.subscribe(() => this.requestUpdate());
	}

	async stateChanged(state) {
		this.state = state;
		this.status = state.status;
		this.errors = state.account.error;
		if (this.updateNeeded) {
			this.updateNeeded = false;
			await store.dispatch(fetchAccount({id: this.accountId})); // fetch account from backend
			// clone data for local editing (component state)
			this.localFormData = selectAccountById(this.state, this.accountId);
		}
	}

	static get routes() {
		return getRoutes();
	}

	async router(route, params, query, data) {

		this.updateNeeded = route === 'admin-account' &&
			(this.accountId !== params.accountId);

		this.params = params;
		this.accountId = params.accountId;
		this.edit = true; // params.mode === 'edit';

		if (route === 'admin-account') {
			this.create = false;
		}
		if (route === 'admin-account-create') {
			this.create = true;
			this.localFormData = deepClone(this.initialData);
		}
		this.requestUpdate();
	}

	handleValueChange(fieldData, value) {
		const section = fieldData.section;
		const field = fieldData.field;

		// update localFormData with new value in section and field
		const newData = deepClone(this.localFormData); // clone current date

		// checkbox: convert string to number if it is a integer
		if (fieldData.type === 'checkbox' && typeof value === 'string' && value.match(/^\d+$/)) {
			value = parseInt(value, 10);
		}

		if (section) {
			if (newData[section]?.[field]) {
				newData[section][field] = value; // update field
			} else {
				newData[section] = {...newData[section], [field]: value}; // create new field
			}
		} else {
			newData[field] = value; // update field
		}
		this.localFormData = newData; // assign (updates reference, triggers update)
	}

	async createAccount() {
		switch (this.localFormData.role) {
			case 'user':
				await store.dispatch(createUser(this.localFormData));
				break;
			case 'admin':
				await store.dispatch(createAdmin(this.localFormData));
				break;
			case 'operator':
				await store.dispatch(createOperator(this.localFormData));
				break;
		}
		store.dispatch(navigateTo({route: 'admin-accounts', params: this.params}));
	}

	async saveAccount() {
		await store.dispatch(updateAccount(this.localFormData));
		this.localFormData = selectAccountById(this.state, this.accountId);
	}

	formHasChanged() {
		// check if local form data is different
		if (!this.localFormData || !this.accountId) return false;

		const newData = deepClone(this.localFormData);
		const oldData = this.create ? deepClone(this.initialData) : deepClone(selectAccountById(this.state, this.accountId));
		delete newData.modified;
		delete oldData.modified;
		return !deepEqual(newData, oldData);
	}

	formComplete() {
		return this.localFormData.email !== '' &&
		this.localFormData.name !== '' &&
		this.localFormData.isGroup === 0 ?
			this.localFormData.singleInfo.firstName !== '' && this.localFormData.singleInfo.lastName !== '' :
			this.localFormData.groupInfo.name !== '';
	}

	cancelEditing() {
		// cancel editing and go back to preview
		store.dispatch(navigateTo({route: 'admin-accounts', params: this.params}));
	}

	editAccount() {
		// go back to editing
		store.dispatch(navigateTo({route: 'admin-accounts', params: this.params}));
	}

	connectedCallback() {
		super.connectedCallback();
	}

	disconnectedCallback() {
		super.disconnectedCallback();
	}

	render() {
		const roleOptions = [
			{value: 'user', label: msg('User')},
			{value: 'admin', label: msg('Admin')},
			{value: 'operator', label: msg('Operator')}
		];
		const accountInputFieldsCreate = [
			{
				type: 'select',
				title: msg('Role'),
				field: 'role',
				section: '',
				options: roleOptions.map((option) => ({
					id: option.value,
					name: [{lang: 'en', value: option.label}]
				})),
				multiple: false,
				data: this.localFormData,
				errors: this.errors,
				required: true,
				handler: this.handleValueChange.bind(this)
			},
			{
				type: 'textInput',
				title: msg('Name'),
				field: 'name',
				section: '',
				placeholder: '',
				data: this.localFormData,
				errors: this.errors,
				required: true,
				handler: this.handleValueChange.bind(this)
			},
			{
				type: 'checkbox',
				title: msg('Is enabled'),
				field: 'isEnabled',
				section: null,
				tooltip: '',
				data: this.localFormData,
				errors: this.errors,
				handler: this.handleValueChange.bind(this)
			},
			{
				type: 'checkbox',
				title: msg('Is Test'),
				field: 'isTest',
				section: null,
				tooltip: '',
				data: this.localFormData,
				errors: this.errors,
				handler: this.handleValueChange.bind(this)
			},
			{
				type: 'checkbox',
				title: msg('Create Profile for User'),
				field: 'withProfile',
				section: null,
				tooltip: '',
				data: this.localFormData,
				errors: this.errors,
				handler: this.handleValueChange.bind(this)
			}
		];
		const accountInputFieldsEdit = [
			{
				type: 'textInput',
				title: msg('Name'),
				field: 'name',
				section: '',
				placeholder: '',
				data: this.localFormData,
				errors: this.errors,
				required: true,
				handler: this.handleValueChange.bind(this)
			},
			{
				type: 'checkbox',
				title: msg('Is enabled'),
				field: 'isEnabled',
				section: null,
				tooltip: '',
				data: this.localFormData,
				errors: this.errors,
				handler: this.handleValueChange.bind(this)
			},
			{
				type: 'checkbox',
				title: msg('Is Test'),
				field: 'isTest',
				section: null,
				tooltip: '',
				data: this.localFormData,
				errors: this.errors,
				handler: this.handleValueChange.bind(this)
			}
		];
		const accountInputFieldsEditEmail = [
			{
				type: 'textInput',
				title: msg('eMail'),
				field: 'email',
				section: '',
				placeholder: '',
				data: this.localFormData,
				errors: this.errors,
				required: true,
				handler: this.handleValueChange.bind(this)
			}
		];

		const languageOptions = [
			{value: 'de', label: msg('German')},
			{value: 'en', label: msg('English')}
		];
		const profileInputFields = [
			{
				type: 'checkbox',
				title: msg('Is artist group'),
				field: 'isGroup',
				section: null,
				tooltip: '',
				data: this.localFormData,
				errors: this.errors,
				handler: this.handleValueChange.bind(this)
			},
			{
				type: 'select',
				title: msg('Default Language'),
				field: 'defaultLang',
				section: null,
				options: languageOptions.map((option) => ({
					id: option.value,
					name: [{lang: 'en', value: option.label}]
				})),
				data: this.localFormData,
				errors: this.errors,
				multilingual: false,
				required: false,
				handler: this.handleValueChange.bind(this)
			}
		];

		const singleInputFields = [
			{
				type: 'textInput',
				title: msg('First Name'),
				field: 'firstName',
				section: 'singleInfo',
				placeholder: '',
				data: this.localFormData,
				errors: this.errors,
				required: true,
				handler: this.handleValueChange.bind(this)
			},
			{
				type: 'textInput',
				title: msg('Last Name'),
				field: 'lastName',
				section: 'singleInfo',
				placeholder: '',
				data: this.localFormData,
				errors: this.errors,
				required: true,
				handler: this.handleValueChange.bind(this)
			},
			{
				type: 'textInput',
				title: msg('Pen Name'),
				field: 'penName',
				section: 'singleInfo',
				placeholder: '',
				data: this.localFormData,
				errors: this.errors,
				handler: this.handleValueChange.bind(this)
			}
		];

		const groupInputFields = [
			{
				type: 'textInput',
				title: msg('Name'),
				field: 'name',
				section: 'groupInfo',
				placeholder: '',
				data: this.localFormData,
				errors: this.errors,
				required: true,
				handler: this.handleValueChange.bind(this)
			}
		];

		return html`
			<div class="formPage">
				<arc-toolbar class="toolbar">
					<div class="leftSlot" slot="left">
						<arc-breadcrumbs>
							<arc-routerlink route="admin-accounts">
								${msg('Accounts')}
							</arc-routerlink>
							<span>${this.create ? msg('New Account') : this.localFormData?.name}</span>
						</arc-breadcrumbs>
					</div>
					<div class="rightSlot" slot="right">
						${when(this.create,
							() => html`
								<arc-button
									title="${msg('Create Account')}"
									type="primary"
									.additionalInfo="${false}"
									?disabled="${!this.formComplete()}"
									class="saveAccountButton"
									@click=${this.createAccount}
								></arc-button>
							`, () => html`
								<arc-button
									title="${msg('Save Account')}"
									type="primary"
									.additionalInfo="${false}"
									?disabled="${!this.formHasChanged()}"
									class="saveAccountButton"
									@click=${this.saveAccount}
								></arc-button>
							`
						)}

					</div>
				</arc-toolbar>
				<header>
					${when(this.create,
						() => html`
							<h2 class="pageTitle">${msg('New Account')}</h2>`,
						() => html`
							<h2 class="pageTitle">${this.localFormData?.name}</h2>
						`)}
				</header>

				<section>
					<div class="leftColumn">
						<h2 class="sectionTitle">${msg('Email')}</h2>
						<arc-form-grid
							class="accountEmail"
							.fields=${accountInputFieldsEditEmail}
							.localFormData=${this.localFormData}
							.errors=${this.errors}
							.edit=${this.edit}>
						</arc-form-grid>
					</div>
					<div class="rightColumn">
					</div>
				</section>

				<section>
					<div class="leftColumn">
						<h2 class="sectionTitle">${msg('Account Information')}</h2>
						<arc-form-grid
							class="accountInfo"
							.fields=${this.create ? accountInputFieldsCreate : accountInputFieldsEdit}
							.localFormData=${this.localFormData}
							.errors=${this.errors}
							.edit=${this.edit}>
						</arc-form-grid>
					</div>
					<div class="rightColumn">
					</div>
				</section>

				${when(
					this.localFormData?.withProfile,
					() => html`
						<section>
							<div class="leftColumn">
								<h2 class="sectionTitle">${msg('Profile Information')}</h2>

								<arc-form-grid
									class="profileInfo"
									.fields=${profileInputFields}
									.localFormData=${this.localFormData}
									.errors=${this.errors}
									.edit=${this.edit}>
								</arc-form-grid>
							</div>
							<div class="rightColumn">
							</div>
						</section>

						<section>
							<div class="leftColumn">
								<h2 class="sectionTitle">${msg('Basic Information')}</h2>

								${when(
									this.localFormData?.isGroup,
									() => html`
										<arc-form-grid
											class="groupInfo"
											.fields=${groupInputFields}
											.localFormData=${this.localFormData}
											.errors=${this.errors}
											.edit=${this.edit}
										>
										</arc-form-grid>
									`,
									() => html`
										<arc-form-grid
											class="singleInfo"
											.fields=${singleInputFields}
											.localFormData=${this.localFormData}
											.errors=${this.errors}
											.edit=${this.edit}
										>
										</arc-form-grid>
									`
								)}
							</div>
							<div class="rightColumn">
							</div>
						</section>
					`)}
			</div>

		`;
	}
}
