import {connect} from 'pwa-helpers';
import {LitElement, html, css} from 'lit';
import {customElement} from 'lit/decorators.js';
import {msg} from '@lit/localize';
import {navigator, router} from 'lit-element-router';
import {when} from 'lit/directives/when.js';
import {repeat} from 'lit/directives/repeat.js';
import deepEqual from 'deep-equal';
import store from '../../store';
import {
	getProfile,
	selectProfileById,
	updateProfile,
	updateProfileCV,
} from '../../slices/profileSlice';
import style from '../../../../scss/artistCv.scss';
import {routeUrl} from '../../util/routeUrl';
import formPageStyle from '../../../../scss/formPage.scss';
import linkstyle from '../../../../scss/link.scss';
import loadingSpinnerStyle from '../../../../scss/loadingSpinner.scss';
import getRoutes from '../../routes';
import {
	selectCurrentContentLanguage,
	selectProfileDefaultLanguage,
	selectProfileDisplayName
} from '../../selectors';
import {getValueForLanguage} from '../../util/getValueForLanguage';
import {deepClone} from '../../util/deepClone';
import {navigateTo, setFormChanged} from '../../slices/statusSlice';
import {trimObject} from '../../util/trimObject';
import up from '../../icons/up';
import down from '../../icons/down';

@customElement('arc-artist-cv')
export class ArtistCv extends connect(store)(router(navigator(LitElement))) {
	static styles = [style, formPageStyle, linkstyle, loadingSpinnerStyle];

	static properties = {
		localFormData: {type: Object, hasChanged: (n, o) => !deepEqual(n, o)},
		errors: {type: Object, hasChanged: (n, o) => !deepEqual(n, o)},
		edit: {type: Boolean},
	};

	constructor() {
		super();
		this.localFormData = null;
		this.errors = null;
		this.isLoading = false;
		store.subscribe(() => this.requestUpdate());
	}

	stateChanged(state) {
		this.state = state;
		this.status = state.status;
		this.formChanged = state.status.formChanged;
		this.errors = state.profile.error;
		this.isLoading = state.profile.loading === 'loading';
		this.contentLanguage = selectCurrentContentLanguage(state);
	}

	static get routes() {
		return getRoutes();
	}

	async router(route, params, query, data) {
		this.params = params;

		this.edit = true; //params.mode === 'edit';
		if (route === 'artist-cv' && this.profileId !== params.id) {
			this.profileId = params.id;
			await store.dispatch(getProfile({id: this.profileId})); // fetch profile from backend
			// clone data for local editing (component state)
			this.localFormData = deepClone(selectProfileById(this.state, this.profileId).cv);
		}
		this.requestUpdate();
	}

	editCv() {
		// edit mode
		store.dispatch(navigateTo({route: 'artist-cv', params: {...this.params, mode: 'edit'}}));
	}

	back() {
		// cancel editing and go back to preview
		store.dispatch(navigateTo({route: 'artist', params: {...this.params, mode: 'preview'}}));
	}

	handleValueChange(index, value) {
		// update localFormData with new value in section and field
		this.localFormData[index] = value; // update field
		this.requestUpdate('localFormData');
		store.dispatch(setFormChanged(this.formHasChanged()));
	}
	handleRemoveSection(index) {
		// remove section at index
		this.localFormData.splice(index, 1); // remove section
		this.requestUpdate('localFormData');
		store.dispatch(setFormChanged(this.formHasChanged()));
	}
	handleAddSection() {
		// add section at end
		this.localFormData.push({
			sectionName: [
				{value: '', lang: 'de'},
				{value: '', lang: 'en'},
			],
			entries: [],
		}); // add section
		this.requestUpdate('localFormData');
		store.dispatch(setFormChanged(this.formHasChanged()));
	}

	connectedCallback() {
		super.connectedCallback();
		store.dispatch(setFormChanged(false));
	}

	disconnectedCallback() {
		super.disconnectedCallback();
		store.dispatch(setFormChanged(false));
	}

	// saveProfile: Edit user profile data, send it to API
	async saveCv() {
		await store.dispatch(updateProfileCV({id: this.profileId, cv: this.localFormData}));
		store.dispatch(setFormChanged(false));
	}

	formHasChanged() {
		if (!this.localFormData) return false;

		// check if local form data is different
		const newData = deepClone(this.localFormData);
		const oldData = deepClone(selectProfileById(this.state, this.profileId).cv);
		return !deepEqual(newData, oldData);
	}

	moveSectionUp(index) {
		if (index > 0) {
			const updatedFormData = deepClone(this.localFormData);
			[updatedFormData[index - 1], updatedFormData[index]] = [
				updatedFormData[index],
				updatedFormData[index - 1],
			];
			this.localFormData = updatedFormData;
			store.dispatch(setFormChanged(this.formHasChanged()));
		}
	}

	moveSectionDown(index) {
		if (index < this.localFormData.length - 1) {
			const updatedFormData = deepClone(this.localFormData);
			[updatedFormData[index + 1], updatedFormData[index]] = [
				updatedFormData[index],
				updatedFormData[index + 1],
			];
			this.localFormData = updatedFormData;
			store.dispatch(setFormChanged(this.formHasChanged()));
		}
	}

	render() {
		const s = store.getState();

		if (this.isLoading || !this.localFormData) {
			return html`
				<div class="loading-overlay">
					<div class="spinner"></div>
				</div>
			`;
		}
		return html`
			<div class="formPage">
				<arc-toolbar class="toolbar">
					<div slot="left">
						<arc-breadcrumbs>
							<arc-routerlink route="artist">${msg('Profile')}</arc-routerlink>
							<span>${msg('Curriculum Vitae')}</span>
						</arc-breadcrumbs>
					</div>
					<div slot="right">
						<arc-button
							title="${msg('Save CV')}"
							type="primary"
							.additionalInfo="${false}"
							?disabled="${!this.formChanged}"
							class="saveCvButton"
							@click=${this.saveCv}
						>
						</arc-button>
					</div>
				</arc-toolbar>
				<header>
					<h2 class="pageTitle">${selectProfileDisplayName(s)}</h2>
					<arc-content-language-switch></arc-content-language-switch>
				</header>
				${repeat(
					this.localFormData,
					(section) => section.id || section.sectionName[0].value,
					(section, index) => html`
						<section>
							<div class="fullColumn">
								<div class="sectionControls">
									<arc-button
										title="${msg('Move Up')}"
										type="onlyIconCv"
										class="upBtnCv"
										.showIcon=${true}
										.showLabel=${false}
										?disabled=${index === 0}
										@click=${() => this.moveSectionUp(index)}
									>
										${up}
									</arc-button>

									<arc-button
										title="${msg('Move Down')}"
										type="onlyIconCv"
										class="downBtnCv"
										.showIcon=${true}
										.showLabel=${false}
										?disabled=${index === this.localFormData.length - 1}
										@click=${() => this.moveSectionDown(index)}
									>
										${down}
									</arc-button>
								</div>
								<div class="cvSection">
									<arc-cv-section
										index=${index}
										.value=${section}
										.errors=${this.errors?.params?.cv?.params?.[index]}
										@value-change=${(e) => this.handleValueChange(e.detail.index, e.detail.value)}
										@remove-section=${(e) => this.handleRemoveSection(e.detail.index)}
									>
									</arc-cv-section>
								</div>
							</div>
						</section>
					`
				)}

				<section>
					<div class="leftColumn">
						<arc-button
							title="${msg('Add Section')}"
							type="primary"
							.additionalInfo="${false}"
							class="addSectionButton"
							@click=${this.handleAddSection}
						>
						</arc-button>
					</div>
					<div class="rightColumn"></div>
				</section>
			</div>
		`;
	}
}
