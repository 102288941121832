import {html} from 'lit';
import {customElement} from 'lit/decorators/custom-element.js';
import {classMap} from 'lit/directives/class-map.js';
import {msg} from '@lit/localize';

import store from '../../../store';
import style from '../../../../../scss/series.scss';
import {FormElement} from './formElement';
import {createSeries, fetchSeries, selectAllSerie} from '../../../slices/artworkSlice';
import {
	selectCurrentContentLanguage,
	selectProfileDefaultLanguage,
	selectCurrentProfileId
} from '../../../selectors';
import {getValueForLanguage} from '../../../util/getValueForLanguage';

@customElement('arc-series')
export class Series extends FormElement {

	static properties = {
		value: {type: String},
		artworkId: {type: String},
		errors: {type: Array},
		tooltip: {type: String},

		newSeries: {type: Object},
		series: {type: Array},
		index: {type: Number}, // index of the element when part of repeat
		type: {type: String} // type of data edited by form element
	};

	static styles = [...FormElement.styles, style];

	initialValue = null;
	newSeries = {
		title: [{lang: 'en', value: ''}, {lang: 'de', value: ''}],
		subTitle: null,
		description: null,
		artworkIds: [],
		profileId: null
	};

	constructor() {
		super();
		this.value = this.initialValue;
	}

	stateChanged(state) {
		this.state = state;
		this.contentLanguage = selectCurrentContentLanguage(state);
		this.series = selectAllSerie(this.state);
		this.seriesOptions = this.series.map((serie) => ({
				id: serie.id,
				name: getValueForLanguage(serie.title, this.contentLanguage, true)
			}
		));
		// add empty option as first option in seriesOptions
		this.seriesOptions.unshift({
			id: 0,
			name: [{lang: 'en', value: 'No Series'}, {lang: 'de', value: 'Keine Serie'}]
		});
		this.profileId = selectCurrentProfileId(state);
		this.newSeries.profileId = this.profileId;
		if (this.newSeries.artworkIds.indexOf(this.artworkId) === -1) {
			this.newSeries.artworkIds.push(this.artworkId);
		}
	}

	handleNewSeriesTitleChange(value) {
		this.newSeries.title = value;
	}
	handleValueChange(value) {
		// const value = Array.from(input.selectedOptions).map(option => option.value);
		this.dispatchValueChange(value);
	}

	render() {
		const s = store.getState();
		if (!this.inputValue) return html``;
		return html`
			<div class="series ${classMap({
				errors: this.hasErrors(),
				changed: this.inputHasChanged(),
				required: this.required
			})}">
				<arc-select
					class="unit"
					.title=${msg('Series')}
					.edit=${true}
					.value=${this.value}
					.errors=${this.errors}
					.options=${this.seriesOptions}
					.multiple=${false}
					.required=${false}
					.tooltip=${this.tooltip}
					@value-change=${(e) => this.handleValueChange(e.detail.value)}>
				</arc-select>
				<div class="addSeries">
					<arc-routerlink route="series-list" smallText>${msg('Manage Series')}</arc-routerlink>
				</div>
			</div>
		`;
	}
}
