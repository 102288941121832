import {connect} from 'pwa-helpers';
import {LitElement, html, css} from 'lit';
import {customElement} from 'lit/decorators/custom-element.js';
import {choose} from 'lit/directives/choose.js';
import {router, navigator, outlet} from 'lit-element-router';
import {configureLocalization} from '@lit/localize';

import {sourceLocale, targetLocales} from '../../generated/locales.js'; // Generated via output.localeCodesModule
import getRoutes from '../routes';
import store from '../store';

import style from '../../../scss/frontend.scss';

import * as templatesEn from '../../generated/locales/en.js';
import * as templatesDe from '../../generated/locales/de.js';
import {navigateTo, setLang, setStatus} from '../slices/statusSlice';
import {routeUrl} from '../util/routeUrl';
import {initSession} from '../slices/accountSlice';

const localizedTemplates = new Map([
	['en', templatesEn],
	['de', templatesDe]
]);

export const {getLocale, setLocale} = configureLocalization({
	sourceLocale: sourceLocale,
	targetLocales: targetLocales,
	// use static locales, because of small size
	loadLocale: async (locale) => localizedTemplates.get(locale)
});

@customElement('arc-frontend')
export class Frontend extends connect(store)(router(navigator(LitElement))) {
	static styles = [style];

	static properties = {
		host: {type: String},
		base: {type: String},
		api: {type: String},
		lang: {type: String}
	};

	static get routes() {
		return getRoutes();
	}

	constructor() {
		super();
		this.route = '';
		this.params = {};
		this.query = {};
		this.lang = 'de';
		setLocale('de');
		store.subscribe(() => this.requestUpdate());
	}

	stateChanged(state) {
		this.status = state.status;
		if (this.api && this.api !== this.status.apiUrl) {
			store.dispatch(
				setStatus({
					host: this.host,
					baseUrl: this.base,
					apiUrl: this.api
				}));
		}
		if (this.params && this.params.language !== this.status.lang) {
			store.dispatch(setLang({lang: this.params.language ?? 'en'}));
		}
	}

	router(route, params, query, data) {
		this.route = route;
		this.params = params;
		this.query = query;
		setLocale(this.params.language ?? 'de');
		if (this.route === 'start') {
			this.navigate(routeUrl('home', {language: 'de'}));
		}
	}

	async connectedCallback() {
		super.connectedCallback();
		await store.dispatch(initSession());
	}

	disconnectedCallback() {
		super.disconnectedCallback();
	}

	isSidAvailable() {
		return store.getState().account.data.api.sid;
	}

	render() {
		if (!this.isSidAvailable()) return null;
		// const status = store.getState().status;

		return html`
			<arc-header></arc-header>
			<main class="frontend">
				${choose(this.route, [
					['start', () => html`
						<arc-home></arc-home>`],
					['home', () => html`
						<arc-home></arc-home>`],
					['account', () => html`
						<arc-account-view></arc-account-view>`],
					['login', () => html`
						<arc-login></arc-login>`],
					['requestToken', () => html`
						<arc-request-token></arc-request-token>`],
					['artist', () => html`
						<arc-artist-profile></arc-artist-profile>`],
					['artist-cv', () => html`
						<arc-artist-cv></arc-artist-cv>`],
					['artist-profiles', () => html`
						<arc-profile-list></arc-profile-list>`],
					['artist-settings', () => html`
						<arc-artist-settings></arc-artist-settings>`],
					['artworks', () => html`
						<arc-artworks></arc-artworks>`],
					['artworks-bin', () => html`
						<arc-artworks-bin></arc-artworks-bin>`],
					['artwork', () => html`
						<arc-artwork-detail></arc-artwork-detail>`],
					['artwork-media', () => html`
						<arc-artwork-media></arc-artwork-media>`],
					['series-list', () => html`
						<arc-series-list></arc-series-list>`],
					['series-detail', () => html`
						<arc-series-detail></arc-series-detail>`],
					['series-create', () => html`
						<arc-series-detail></arc-series-detail>`],
					['cv-preview', () => html`
						<arc-cv-section></arc-cv-section>`],
					['admin-accounts', () => html`
						<arc-admin-accounts></arc-admin-accounts>`],
					['admin-account', () => html`
						<arc-create-account></arc-create-account>`],
					['admin-account-create', () => html`
						<arc-create-account></arc-create-account>`]
				])}
			</main>
			<arc-confirmation-dialog></arc-confirmation-dialog>
			<arc-upload-progress></arc-upload-progress>
		`;
	}
}
