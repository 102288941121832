import {connect} from 'pwa-helpers';
import {LitElement, html} from 'lit';
import {customElement} from 'lit/decorators/custom-element.js';
import {repeat} from 'lit/directives/repeat.js';
import {msg} from '@lit/localize';
import {when} from 'lit/directives/when.js';
import deepEqual from 'deep-equal';
import store from '../store';
import style from '../../../scss/formRepeat.scss';
import plus from '../icons/plus';
import up from '../icons/Up';
import down from '../icons/Down';
import close from '../icons/close';

@customElement('arc-form-repeat')
export class FormRepeat extends connect(store)(LitElement) {
	static styles = [style];

	static properties = {
		components: {type: Array}, // list of components that can be added
		data: {type: Array, hasChanged: (n, o) => !deepEqual(n, o)}, // the data array of the components
		errors: {type: Array, hasChanged: (n, o) => !deepEqual(n, o)}, // partial errors (only for the components data)
		repeatComponents: {type: Array, hasChanged: (n, o) => !deepEqual(n, o)}, // the components that are repeated
	};

	constructor() {
		super();
		this.components = [];
		this.repeatComponents = [];
		store.subscribe(() => this.requestUpdate());
	}

	stateChanged(state) {
		this.state = state;
		this.status = state.status;
	}

	connectedCallback() {
		super.connectedCallback();
		this.repeatComponents = [];
		// add components based on data
		this.buildComponents();
	}
	buildComponents() {
		this.repeatComponents = [];
		this.data.forEach((data, index) => {
			let componentData;
			if (!data.type && this.components.length === 1) {
				componentData = this.components[0];
			} else {
				componentData = this.components.find((component) => component.type === data.type);
			}
			if (this.errors) {
				componentData.errors = this.errors[index];
			}
			if (componentData) {
				this.addComponent(componentData, data);
			} else {
				console.log('no component found for ', data.type);
			}
		});
	}

	disconnectedCallback() {
		super.disconnectedCallback();
	}

	handleValueChange(detail) {
		this.data[detail.index] = detail.value;
		if (detail.type) {
			this.data[detail.index].type = detail.type;
		}
		this.dispatchEvent(new CustomEvent('value-change', {detail: {value: this.data}}));
	}

	handleAddComponent(componentIndex) {
		// add new component to repeatComponents
		const componentData = this.components[componentIndex];
		const newComponent = this.addComponent(componentData);

		// add initialData of added component to data
		this.data[newComponent.index] = newComponent.getInitialValue();
		newComponent.edit = true;
		this.requestUpdate('data');

		this.dispatchEvent(new CustomEvent('value-change', {detail: {value: this.data}}));
	}
	handleRemoveComponent(index) {
		// remove component and data
		this.data.splice(index, 1);
		this.buildComponents();
		//this.repeatComponents.splice(index, 1);
		this.requestUpdate('data');
		this.dispatchEvent(new CustomEvent('value-change', {detail: {value: this.data}}));
	}
	handleUpComponent(index) {
		// resort repeatComponents by moving component up
		if (index > 0) {
			this.swap(index, index - 1);
			this.buildComponents();

			this.requestUpdate('data');
			this.dispatchEvent(new CustomEvent('value-change', {detail: {value: this.data}}));
		}
	}
	handleDownComponent(index) {
		// resort repeatComponents by moving component down
		if (index < this.repeatComponents.length - 1) {
			this.swap(index, index + 1);
			this.buildComponents();

			this.requestUpdate('data');
			this.dispatchEvent(new CustomEvent('value-change', {detail: {value: this.data}}));
		}
	}
	swap(a, b) {
		const tempData = this.data[a];
		this.data[a] = this.data[b];
		this.data[b] = tempData;
	}
	// create a new component based of componentData and add it to repeatComponents
	// if contentData is provided, assign it to the new component
	addComponent(componentData, contentData) {
		const newRepeatComponents = [...this.repeatComponents];

		const newComponent = document.createElement(componentData.component);

		// assign all attributes of componentData to newComponent
		Object.keys(componentData).forEach((key) => {
			newComponent[key] = componentData[key];
		});

		// assign value to new Component
		newComponent.value = contentData;
		newComponent.index = this.repeatComponents.length;

		// add event listener to handle value changes
		newComponent.addEventListener('value-change', (ev) => this.handleValueChange(ev.detail));

		// add newComponent to repeatComponents
		newRepeatComponents.push(html`
			<div class="componentContainer">
				<div class="controls">
					<span class="index">${newComponent.index + 1}.</span>
				</div>
				<div class="controls">
					${when(
						newComponent.index > 0,
						() => html`
							<arc-button
								key=${newComponent.index}
								title="${msg('up')}"
								type="onlyIcon"
								class="upBtn"
								.showIcon=${true}
								.showLabel=${false}
								@click=${(e) => this.handleUpComponent(newComponent.index)}
								>${up}</arc-button
							>
						`
					)}
					${when(
						newComponent.index < this.data.length - 1,
						() => html`
							<arc-button
								key=${newComponent.index}
								title="${msg('down')}"
								type="onlyIcon"
								class="upBtn"
								.showIcon=${true}
								.showLabel=${false}
								@click=${(e) => this.handleDownComponent(newComponent.index)}
								>${down}</arc-button
							>
						`
					)}
				</div>
				<div class="component">
					${when(
						this.components.length > 1,
						() => html`
							<div class="head">
								<div class="label">${componentData.label}</div>
							</div>
						`
					)}
					${newComponent}
				</div>
				<div class="controls">
					<arc-button
						key=${newComponent.index}
						title="${msg('delete')}"
						type="onlyIcon"
						class="removeBtn"
						.showIcon=${true}
						.showLabel=${false}
						@click=${(e) => this.handleRemoveComponent(newComponent.index)}
						>${close}</arc-button
					>
				</div>
			</div>
		`);
		this.repeatComponents = newRepeatComponents;
		return newComponent;
	}

	render() {
		return html`
			${this.repeatComponents}

			<div class="addButtons">
				${repeat(
					this.components,
					(component) => component.field,
					(component, index) => html` <arc-button
						key=${index}
						title="${component.label}"
						type="secondary"
						class="addBtn"
						.showIcon=${true}
						@click=${(e) => this.handleAddComponent(index)}
						>${plus}</arc-button
					>`
				)}
			</div>
		`;
	}
}
