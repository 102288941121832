import {connect} from 'pwa-helpers';
import {LitElement, html, css} from 'lit';
import {msg} from '@lit/localize';
import {customElement} from 'lit/decorators/custom-element.js';
import {classMap} from 'lit/directives/class-map.js';
import {repeat} from 'lit/directives/repeat.js';

import store from '../../store';

import style from '../../../../scss/contentLanguageSwitch.scss';
import {
	selectAvailableContentLanguages,
	selectCurrentContentLanguage,
	selectProfileDefaultLanguage,
} from '../../selectors';
import {setPreferredLanguage} from '../../slices/accountSlice';
import {setCurrentContentLanguage} from '../../slices/statusSlice';

@customElement('arc-content-language-switch')
export class ContentLanguageSwitch extends connect(store)(LitElement) {
	static styles = [style];

	static properties = {
		currentContentLanguage: {type: String},
		contentLanguages: {type: Array},
	};

	contentLanguages = [];

	constructor() {
		super();
		this.currentContentLanguage = 'de';
		store.subscribe(() => this.requestUpdate());
	}

	stateChanged(state) {
		this.state = state;
		this.contentLanguages = selectAvailableContentLanguages(state);
		this.currentProfileDefaultLanguage = selectProfileDefaultLanguage(state);
		this.currentContentLanguage = selectCurrentContentLanguage(state);
	}
	connectedCallback() {
		super.connectedCallback();
	}

	switchLanguage(language) {
		store.dispatch(setCurrentContentLanguage(language));
		window.dispatchEvent(
			new CustomEvent('content-language', {
				bubbles: true,
				detail: {language: language}
			})
		);
	}

	render() {
		console.log(this.contentLanguages, this.currentContentLanguage);
		return html`
			<div class="contentLanguageSwitch">
				<div class="languages">
					${repeat(
						this.contentLanguages,
						(language) => language,
						(language) => html`
							<button
								class="contentLanguageButton ${classMap({active: this.currentContentLanguage === language})}"
								?disabled=${this.currentContentLanguage === language}
								@click=${() => this.switchLanguage(language)}
							>
								${language.toUpperCase()}
							</button>
						`)}
				</div>
				<arc-tooltip .text=${msg('content_language_tooltip')}></arc-tooltip>
			</div>
		`;
	}
}
