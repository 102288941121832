import {LitElement, html} from 'lit';
import {customElement} from 'lit/decorators.js';
import {navigator, router} from 'lit-element-router';
import {msg, str} from '@lit/localize';
import {when} from 'lit/directives/when.js';
import {classMap} from 'lit/directives/class-map.js';

import {connect} from 'pwa-helpers';

import style from '../../../../scss/artworkListItem.scss';
import store from '../../store';
import {
	deleteArtwork,
	undeleteArtwork
} from '../../slices/artworkSlice';
import {getValueForLanguage} from '../../util/getValueForLanguage';
import {openDialog} from '../../slices/statusSlice';
import {routeUrl} from '../../util/routeUrl';
import getRoutes from '../../routes';
import {selectCurrentContentLanguage, selectProfileDefaultLanguage, selectLanguage} from '../../selectors';

@customElement('arc-artwork-list-item')
export class ArtworkListItem extends connect(store)(router(navigator(LitElement))) {
	static styles = [style];

	static properties = {
		artwork: {type: Object},
		contentLanguage: {type: String}
	};

	constructor() {
		super();
		store.subscribe(() => this.requestUpdate());
	}

	stateChanged(state) {
		this.state = state;
		this.lang = selectLanguage(state);
		this.contentLanguage = selectCurrentContentLanguage(state);
	}

	static get routes() {
		return getRoutes();
	}

	router(route, params, query, data) {
		this.route = route;
		this.params = params;
		this.isBin = route === 'artworks-bin';
		this.isSeries = route === 'series-detail';
	}

	connectedCallback() {
		super.connectedCallback();
	}

	handleEditArtwork(artworkId) {
		if (!this.isBin) {
			this.navigate(routeUrl('artwork', {...this.params, ...{artworkId: this.artwork.id, mode: 'edit'}}));
		}
	}

	handleDeleteArtwork(artworkId) {
		const title = getValueForLanguage(this.artwork.title, this.contentLanguage, true);
		store.dispatch(
			openDialog({
				title: msg('Remove Artwork'),
				message: msg(
					'Removed Artworks containing data are available in the Removed Artworks for 7 days after removal. Empty artworks are removed immediately.'
				),
				warningMessage: msg(
					str`Removing Artwork "${title}" will immediately remove it from your list of Artworks.`
				),
				buttonText: msg('Remove Artwork')
			})
		);
		this._handleConfirm = async () => {
			await store.dispatch(deleteArtwork({id: artworkId}));
			this.dispatchEvent(
				new CustomEvent('artwork-deleted', {
					bubbles: true,
					composed: true,
					detail: {artworkId: artworkId},
				})
			);
			window.removeEventListener('confirm', this._handleConfirm);
		};
		window.addEventListener('confirm', this._handleConfirm);
	}

	handleRestoreArtwork(artworkId) {
		store.dispatch(undeleteArtwork({id: artworkId}));
	}

	getPublicationStateValue() {
		if (this.artwork.status === 'draft' && this.artwork.visibility === 'private') {
			return msg('draft');
		} else if (this.artwork.status === 'published' && this.artwork.visibility === 'private') {
			return msg('private');
		} else if (this.artwork.status === 'published' && this.artwork.visibility === 'public') {
			return msg('public');
		} else {
			return '';
		}
	}

	render() {
		const title = getValueForLanguage(this.artwork.title, this.contentLanguage, true);
		return html`
			<div class="artworkItem ${classMap({binItem: this.isBin, seriesItem: this.isSeries})}"
					 @click="${this.handleEditArtwork}">
				<div class="artworkDetail">
					<arc-image
						class="artworkImage"
						.file=${this.artwork.firstImage}
						alt="${getValueForLanguage(
							this.artwork.firstImage?.info?.alt,
							this.contentLanguage,
							true
						)}"
					>
					</arc-image>
				</div>
				<div class="artworkDetail">${this.artwork.inventoryNumber ?? msg('Unknown')}</div>
				<div class="artworkDetail">
					<h1>
						${when(
							title !== '',
							() => html`${title}`,
							() => html`${msg('Untitled')} (${this.artwork.id})`
						)}
					</h1>
				</div>
				<div class="artworkDetail">
					${when(
						this.artwork.materials,
						() => html`
							${this.artwork.materials
								.map((material) => getValueForLanguage(material, this.contentLanguage, true))
								.join(', ')}
						`
					)}
				</div>
				<div class="artworkDetail">
					${this.artwork.artworkDate ? this.artwork.artworkDate.split('-')[0] : msg('Unknown')}
				</div>
				<div class="artworkDetail">${this.getPublicationStateValue()}</div>
			</div>
			<div class="artworkDetail artworkListButtons">
				${this.isBin
					? html`
						<arc-dropdown-menu>
							<arc-button
								class="restoreButton"
								title="${msg('Restore')}"
								type="success"
								@click=${() => this.handleRestoreArtwork(this.artwork.id)}>
								${msg('Restore')}
							</arc-button>
						</arc-dropdown-menu>`
					: html`
						<arc-dropdown-menu>
							<arc-button
								type="deleteButton"
								title="${msg('Remove')}"
								@click=${() => this.handleDeleteArtwork(this.artwork.id)}>
								${msg('Remove')}
							</arc-button>
						</arc-dropdown-menu>`}
			</div>
		`;
	}
}
