
    // Do not modify this file by hand!
    // Re-generate this file by running lit-localize

    
    import {str} from '@lit/localize';

    /* eslint-disable no-irregular-whitespace */
    /* eslint-disable @typescript-eslint/no-explicit-any */

    export const templates = {
      's032cd9f1245d3645': `Exhibition space`,
's0491d2d22458cf73': `exceeded number of videos`,
's071d99b3ab93bbcb': `Artist group`,
's072af2f9f637f289': `invalid language`,
's08467b5f9b0bbe38': `Add your own keywords to filter by in the overview`,
's087eb6b22eae8f9c': `Add artwork`,
's08d844177965c733': `Reset filters`,
's0c599150290fb992': `not a string`,
's0f15b434f2922b08': `whole number`,
's0f2cb376eeeaa423': `Year (start)`,
's11366a6d9faff00f': `confirmation required`,
's1829ba489430c269': `Kunstfonds inventory number`,
's1946c13a177a90a1': `Object numbers`,
's1add68562a420778': `Detailed definition of used materials`,
's1b5bba3d9041629a': `Save profile settings`,
's1c1134cbda4fe1ee': `invalid format`,
's219c37011b334110': `Add media`,
's24d03b95f001a869': `Remove artwork`,
's24ed5ee1f343a2b4': `only partial upload received`,
's292da8ff092860a5': `Select state...`,
's2a94ce1cb475c9c2': `You can invite people to help you create this profile. They can view and edit all artworks and
					profile data.
				`,
's2c57913ffd36d298': `First name`,
's30c9440f8f06fc3c': `exceeded number of files`,
's31b9c53d98feb660': `Request login code`,
's31d694b87ea70f4d': `invalid date`,
's32b3ac51a9c31f47': `Switch to profile`,
's336c8e1678a8ff80': `Remove item`,
's33b30147022d7f8c': `Enter code`,
's34aa94e923bb3d75': `Out of set`,
's383f38fbc7a28b77': `file to big`,
's3dc7f921134f8d35': `In Kunstfonds� Werkverzeichnisse, you can edit your artist profile, curriculum vitae and
					artworks as simple or as detailed as you wish.
				`,
's402ed9d74111a751': `Creation date to`,
's477bc7abb235cce6': `Hide filters`,
's47a2a1f01af73025': `Day of death (if applicable)`,
's4fcd634b4ebc761b': `Add section`,
's523f5d97bb322419': `Account settings`,
's552ca8336fe964d0': `Exhibition history`,
's5540c926a41a6be9': `Add profile`,
's58d4bced0b668ae6': `not a number`,
's5a41c3a63f1c18af': `Profile setting`,
's63e457bc703a3030': `E-mail`,
's65bbdef13ec0b7ff': `Enter title`,
's65bf0994a56d0c51': `Here you can enter a continuous text. For a detailed tabular list, e.g. of the exhibition
					history, see the CV section
				`,
's6801787e32a10951': `In Kunstfonds� Werkverzeichnisse, you can edit your artist profile, curriculum vitae and
					artworks as simple or as detailed as you wish.
				`,
's6cedf419e20c815a': `Phone`,
's6cfdba3baed1f6bc': `Title of Artwork`,
's6e0f65a28704ec9f': `Section name`,
's73cdb5bba903a27f': `Replace file`,
's77585085193f50c2': `invalid URL`,
's7a851c97b5bc30b8': `not an IntegerBool`,
's7c34d7a176d95aa0': `Year (end)`,
's7d38fc29b20f951b': `Basic information`,
's821ef36d63a8a111': `Last modified on`,
's8362459ef00898be': `Subtitle of Artwork`,
's846b3790b6e25c1c': `Account`,
's855aaab4663315b4': `Artworks`,
's8641b6c3688a0cec': `Edit artists profile`,
's92921878e886e36d': `Period of collaboration`,
's92fef0e2fba7468c': `Language settings`,
's931ba7d0f5588be8': `Default language`,
's9502410942da6b88': `CV entry`,
's95a75a2392af5780': `Last name`,
's9739d36feb7c7948': `file type not allowed`,
's9d0a3b8de87941d8': `Purchasing price`,
's9e92503bfc80d2d6': `Genre/Medium of artwork`,
'sa0541ec14dd4b89c': `Rejection required`,
'sa080ed80aad67329': `file to long`,
'sa1dc38bb8e8339f3': `Pen name`,
'sa2686cd9fcc26088': `Save artwork`,
'sa5d2f9afa34fd3c3': `Request code`,
'sabe442739ffbe072': `Maximum size`,
'sad3e3c8146fc920f': `State`,
'sae42bf06e5c4bbe5': `Enter e-mail`,
'sb28076159e581755': `Edit images / media`,
'sb41210de4f64f7e1': `zero required`,
'sb651be2136f4a42c': `A short description of this artwork`,
'sb8bf01b381b893bb': `one required`,
'sbc2cea55676b3aca': `Enter the email address to which the login code should be sent.`,
'sc047622d98fe58c5': `Catalogue of works`,
'sc14632800a845f4a': `Select tags`,
'sc2d94a6963318685': `Edit your artists profile`,
'sc34b221a0b8bc676': `upload error`,
'sc3693cd85fbd07d3': `not a boolean`,
'sc4bbedc293a299bb': `file is missing`,
'sc5b9d5ea3bd15972': `This number is automatically assigned incrementally`,
'sc622b6f2e8694aac': `Save media`,
'sc7bcd1669fd8e336': `Add child artwork`,
'sc93a9aa3e5bcbf5d': `Save profile`,
'scbbbf3cce4237b03': `required field`,
'scc316262f13a1da8': `not an integer`,
'scd6ac002544eb0d6': `Value category`,
'scf8fcc133a2f8f5b': `Enter profile name`,
'scfa9e1e719a45ae3': `input to long`,
'scfce1140fb4a263f': `Select artists profile to edit`,
'sd1f44f1a8bc20e67': `E-mail`,
'sd24d2099f6f4a88b': `Tabular curriculum vitae`,
'sd33f062c19e2e331': `Profile name`,
'sd55bb7d228718163': `invalid email format`,
'sdc8ff1d18f2ecc66': `not an integer greater 0`,
'se17f76f493601b62': `https:// ... (link to representation of the artwork)`,
'se2abc0f78904006c': `max`,
'se2c78eaae9032faa': `Objects and measurements`,
'se330c71fbddb77c9': `Location of birth`,
'se625ae9f34125ec4': `invalid ID`,
'se629b41ac62684a1': `Additional copyright`,
'se68398e3c2c760b2': `Code`,
'se6a9371ded4b10d6': `Sub artworks`,
'se6ae9710a2dbbf88': `Owners history`,
'se77e76829df75be9': `Add series`,
'se80d078fe2e23924': `not an array`,
'se926a1be4644b09d': `Stay logged in`,
'sebec66b8aa58f583': `not a float`,
'sec72dce4f74b5328': `Creation date from`,
'see1bbbec880f6085': `Usage notes`,
'sef16aaaba716aa7d': `Amount of objects`,
'sf61497d51f2c663f': `Deleted artworks`,
'sf6947e1f919e6815': `input to short`,
'sfa31cf00458e1d13': `More filters`,
'sfa766d8b35773ac0': `Location of death (if applicable)`,
'sfa889eb8ea9148b1': `Artwork date`,
'sfb608c3ab924e85c': `file category not allowed`,
'sfbb763a33ac5097b': `Current condition`,
'sfde5ee686b1d3d02': `Images / media copyright`,
'sfe4a9344e132c10a': `Materials used`,
'sff9d51b6c5a73163': `Alternative text`,
'sb01af87e9ecf6fe9': `You have unsaved changes. Do you really want to leave?`,
's814ca8490eb230c0': `Artwork added successfully.`,
'sf678df8359ba6257': `Failed to add artwork. Please try again.`,
'sf9e39190a7ba37b4': `Profile saved successfully.`,
's7858dcc121b436fc': `The profile cannot be saved. Please correct the errors.`,
'sb0f8ba7eed402d21': `Curriculum vitae saved successfully.`,
's49c6478ea452050b': `The curriculum vitae cannot be saved. Please correct the errors.`,
's5e9f4a47a1f2cd25': `Artwork saved successfully.`,
's64a6dcf9cd66376f': `The form cannot be saved. Please correct the errors.`,
'se82d6481ffb5248f': `Media data saved successfully`,
's5d7d95ba0d2c4869': `The media date cannot be saved. Please correct the errors.`,
's345fbf4889ae0c7f': `The artwork contains child artworks, please delete them first.`,
's1c43540556a6117c': `The invitation could not be sent.`,
'sda5c7c6d6aeb3699': `The invitation was sent successfully.`,
's69201fa8972b3e66': `Artwork did not contain any data - it was permanently deleted.`,
'sa87645df04d5260a': `Artwork was moved to the Removed Artworks. It will be deleted in 7 days.`,
's08c43a07b566d980': `up`,
'sdc04126761bb7455': `down`,
'sf3fe6b5fdb85d50a': `delete`,
'seae1c1bb7c02a640': `error/invalidCsrfToken`,
'sa65a95e0199f2647': `error/invalidInput`,
's881e3183ff4b701d': `error/uploadMissingTmpDir`,
's860dbc68e0f2b4c8': `error/uploadUnableToWrite`,
'sc21dc776d25eb55a': `error/uploadExtensionFailure`,
's5e8250fb85d64c23': `Close`,
'sd9aa257179753e02': `Uploading File`,
's03848d257276adc8': `exact date`,
'se7238e44f9dcacee': `year only`,
'seb4bdd2a26f2ea23': `Select options`,
's5d929ff1619ac0c9': `Search`,
'sec7b97c2e906c8fc': `Start typing...`,
'sef49aec68fd1dc66': `Name`,
's41f7dca46d3f1b4a': `Value`,
's3375a4920652b810': `Series`,
's45ab758ffe13fd9b': `Manage Series`,
's5590dbf7e425789d': `Label`,
'sf21fb4f4b9e8911f': `Width`,
'sffdadf0a576fd802': `Height`,
's3231d0dc6fe30d4a': `Depth`,
's4cd84215487b493a': `Diagonal`,
's21900f027f2d7cfa': `Diameter`,
's517acbf1f96324f9': `Weight`,
's7dfa029dab05b042': `Edit Artworks`,
's1e61bece71b51008': `Removed artworks`,
'sa84953c4bf74abca': `Edit Series`,
'sb061ff5a347a296e': `Profile`,
's0f8a40190d30e7e3': `Edit CVs`,
'saae1c70e168b45b4': `Admin`,
'se48bd9e6c2a3109d': `Accounts`,
's67749057edb2586b': `Logout`,
'sc8da3cc71de63832': `Login`,
's6d8c02aee480af7a': `Menu`,
's7f1c2ac56a84f421': `Welcome to Werkverzeichnisse of Stiftung Kunstfonds`,
's64eb05dfa49088da': `Manage Accounts`,
's09240e07b5b8d640': `ID`,
'sca7fed2bef53cb99': `Role`,
's332a5235948c1a1d': `Consent`,
'sca974356724f8230': `Log In`,
's6cbf5b3254dcbbf3': `Request code again`,
'sf0175a45c199e862': str`login code sent to ${0}`,
's804ca9ce39d5c56b': `Request Code`,
's13e72a6392474063': `Profile switched`,
's3a6129602c615ff1': `edit`,
'sfe46f400c6b86658': `view`,
's09242e07b5b90ca0': `Id`,
'sb971debc37bf0b54': `Owner`,
's6c3970ea23f6d93a': `Profile Permissions`,
'sbe9e66b2467b273b': `Artwork Permissions`,
's99f110d27e30b289': `Title`,
's8a6c0085cb6c64a0': `Gender`,
's32ac2b6848504809': `Nationality`,
's93c046d8c5bb2047': `Date of birth`,
's165d3919b108ec51': `Bio`,
'see2fcaedf9743ec6': `Location`,
'se077a13a6f7be5e3': `Duration of Collaboration`,
's0d045841254adc83': `Short Description`,
's82665b2ffabc9c0a': `Website`,
'sfcdd8e672c75b713': `Address`,
's2468b69d10791c82': `City`,
'saffa23915bede5a1': `Zipcode`,
's9cf7402fe920f7d1': `Country`,
's0e71a5c50cd56f90': `Add Profile Image`,
's022ad53b239795d5': `Remove Profile Image`,
's87bae2710b2492c3': `Contact`,
's8677baad3bf6b295': `Save CV`,
's4e33f21fc24666c1': `Move Up`,
's87a55464116c3f48': `Move Down`,
's63e71d20d1eaca93': `German`,
's4caed5b7a7e5d89b': `English`,
's9b1394a1375404d4': `Is Remains`,
's9d8b8aa2b404c2c8': `Settings`,
'sc2c901ea4b99a96a': `Remains`,
's8d8622e68bd10109': `Invite Editor`,
's948766ad031e1c27': `eMail`,
's4ced1bd295c3cac3': `Enter EMail to sent invitation`,
's19b937c88ca5d2b3': `Invite User`,
'sea4f08110bb8f15d': `Remove`,
'sa7e28eff7384ceee': `Mark as important`,
's3cf89cb47fdde7e9': `Link`,
's63d894b1ddb06289': `Description`,
's0379fc73608ab971': `Done`,
's64ef2a6c2dd1d3d1': `Edit`,
's37083e19c2f9c755': `Nr.`,
's5096bdb5f882fb29': `Materials`,
'sa6f2645578b2d2bc': `Year`,
'seca29ce17b31f70d': `Publication`,
'sca08a0a32920416b': `No artworks found.`,
'sdcb9b5b53f8c8fc7': `Removed Artworks containing data are available in the Removed Artworks for 7 days after removal. Empty artworks are removed immediately.`,
's0c6538ad32621450': str`Removing Artwork "${0}" will immediately remove it from your list of Artworks.`,
's1641a1c90d9840ec': `draft`,
'sc5a11c2dd9ab8cec': `private`,
'sf4b72165f60f5b60': `public`,
'sc592307ea80f16b9': `Unknown`,
's927497135cdabe78': `Untitled`,
's26f91b6796e33111': `Restore`,
's1219d97b49650c05': `Visibility`,
's35f1f5ccf4706d78': `Select visbility...`,
'sd2223afb7d6b100d': `Type`,
'sbec9dc4ffcfd8ecb': `Select type...`,
'sb19c9290038389c0': `Material`,
's9cafd50042887a6c': `Select material...`,
'sd600892f7fabe04c': `Draft`,
'se7bee6e9a9b5394c': `Private`,
'sdd1ff479d04ac140': `Public`,
's06b291180b5c2ef2': `Removing this Artwork will immediately remove it from your list of Artworks.`,
's0f0202c2780ce439': `Subtitle`,
's38be98a354b0a135': `Signed`,
's37f2ed08cc75dc54': `placeholder_artwork_technique`,
'se80bc227790b7f05': `tooltip_artwork_series`,
's03aa3cfb08dff960': `Tags`,
'scbf29ce484222325': ``,
's8ac6ca24f22e5e3f': `Sold`,
'sc6a7cb64b423cffa': `Loaned`,
's34d7c565f291023e': `Unsellable`,
'sae239213b7c70376': `State`,
's4880dc3125543292': `Mentions`,
'sd80496e9c65f1366': `Remarks`,
'sd27dd35de5df9273': `placeholder_artwork_purchasingPrice`,
's0bd4477ce8ffb997': `placeholder_artwork_valueCategory`,
'se5e21c784a22e2bc': `placeholder_artwork_currentCondition`,
's3713b9bb37a2509a': `placeholder_artwork_usageNotes`,
'sdc673e73b5c13aea': `Delete`,
's43e372d3fcabbd0a': `Basic attributes`,
's72f2a02e2a5a4ee3': `Media`,
's5f175fdca113e5f1': `Additional Inventory Number`,
'sf74160a9dc86ab8f': `Resolution`,
'sb9cdfae56d72cfe2': `Provenance`,
's2a75c946ef4dcab7': `Miscellaneous`,
's13df6bd38cbf23ad': `Valid formats`,
's7d8d449c7a0483c8': `No media available for this artwork.`,
's832ef3a7593a8b05': `Caption`,
'sf3282d80d86fcdc4': ` View PDF`,
's5ccc0b389bfe6048': `No series available`,
'se48719d765fc8fde': `Remove Series`,
's9cbe5dc90f48558d': str`Removing Series "${0}" will immediately delete it. The artworks in this series remain in the artwork list.`,
'scb7ed089a649241c': `Create New Account`,
's70d885806b56289e': `enabled`,
'sf9e6e6ef197c2b25': `test`,
's9c470e19f779025e': `has logged in`,
's68631cfbcbebfc30': `No accounts found.`,
's965c503c3e42fdfe': `Enabled`,
's2474e7fb1aec9f05': `Test`,
'se3e144f19cb4e18f': `Has Logged In Once`,
's016f68d263953ec2': `Remove Account`,
's63b877fd01da50e6': str`Removing Account "${0}" will immediately remove it from your list of Accounts.`,
'scb489a1a173ac3f0': `Yes`,
's09205907b5b56cda': `No`,
'sa48f81f001b893d2': `User`,
'sc4801f580ac4e73d': `Operator`,
's743edce4a1ed0b34': `Is enabled`,
's8a0e1c7e1ec3133b': `Is Test`,
'sf76a183603d87542': `Create Profile for User`,
'sa512fd0aa10743a6': `New Account`,
's321c03399a143242': `Create Account`,
's744bab01318e5b27': `Save Account`,
's17fc59d42b9bc362': `Account Information`,
's3f665d5e5aaa691c': `Profile Information`,
    };
  