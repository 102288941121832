import {html} from 'lit';
import {customElement} from 'lit/decorators/custom-element.js';
import {classMap} from 'lit/directives/class-map.js';
import {when} from 'lit/directives/when.js';

import style from '../../../../../scss/radio.scss';
import {FormElement} from './formElement';
import {isArray} from '../../../../cdlx/utils/types';

@customElement('arc-radio')
export class Radio extends FormElement {

	static properties = {
		...FormElement.properties,
		name: {type: String},
		options: {type: Array}, // options to select from
	};

	static styles = [style];

	initialValue = '';

	stateChanged(state) {
		super.stateChanged(state);
	}

	handleValueChange(e) {
		this.dispatchValueChange(e.target.value);
	}
	isSelected(option) {
		return this.value + '' === option.id + '';
	}

	render() {
		if (!this.edit) {
			return html`
				<div>
					<div class="textInputInfo">
						<div class="title">${this.title}</div>
					</div>
					<p class="value">${this.value}</p>
				</div>
			`;
		}
		return html`
			<div class="formField ${classMap({errors: this.hasErrors(), changed: this.inputHasChanged()})}">
					${when(this.title, () => html`
						<div class="textInputInfo">
							<div class="title">
								${this.title} ${when(this.required, () => html`<span class="requiredMark">*</span>`)}
							</div>
							${when(this.tooltip, () => html`
								<arc-tooltip text=${this.tooltip}></arc-tooltip>`)}
						</div>
					`)}
					${this.icon ? html`<span class="icon"><slot name="icon"></slot></span>` : ''}
					${this.options.map(option => (html`
						<div class="radioOption">
							<input type="radio" name="${this.name}" value=${option.id} id=${option.id}
										 .checked=${this.isSelected(option)}
										 @change=${(this.handleValueChange)}></input>
							<label for="${option.id}">
								${isArray(option.name) ?
									(option.name.find(name => name.lang === this.lang)?.value || option.name[0].value) :
									option.name}
							</label>
						</div>
					`))}
				${when(this.hasErrors(), () => html`<arc-errors .errors=${this.errors}></arc-errors>`)}
			</div>
		`;
	}
}
