import {createSlice} from '@reduxjs/toolkit';
import {msg} from '@lit/localize';
import {routeUrl} from '../util/routeUrl';
import {artworkSlice} from './artworkSlice';

const statusInitialState = {
	status: 'idle',

	// ui state
	isHeaderDropdownOpened: false,
	isAdminDropdownOpened: false,
	isProfileDropdownOpened: false,
	isArtworksDropdownOpened: false,

	// base urls
	host: null,
	baseUrl: null,
	apiUrl: null,

	// route
	route: {
		route: null,
		params: null,
		query: null
	},

	// ui language
	lang: '',

	// content language
	currentContentLanguage: 'de',
	availableContentLanguages: ['de', 'en'],

	dialog: {
		open: false,
		message: '',
		title: '',
		warningMessage: '',
		artworkTitle: '',
	},

	error: {},
	message: null,
	messageType: null,
	showMessage: false,

	formChanged: false
};

export const statusSlice = createSlice({
	name: 'profile',
	initialState: statusInitialState,
	reducers: {
		openDialog: (state, action) => {
			state.dialog.open = true;
			state.dialog.message = action.payload.message;
			state.dialog.title = action.payload.title || '';
			state.dialog.warningMessage = action.payload.warningMessage || '';
			state.dialog.artworkTitle = action.payload.artworkTitle || '';
		},
		closeDialog: (state) => {
			state.dialog.open = false;
			state.dialog.message = '';
			state.dialog.title = '';
			state.dialog.warningMessage = '';
			state.dialog.artworkTitle = '';
		},
		navigateTo: (state, action) => {
			// close header menus
			state.isAccountDropdownOpened = false;
			state.isAdminDropdownOpened = false;
			state.isProfileDropdownOpened = false;
			state.isArtworksDropdownOpened = false;

			// eslint-disable-next-line no-alert
			if (!state.formChanged || window.confirm(msg('You have unsaved changes. Do you really want to leave?'))) {
				state.route = {
					route: action.payload.route,
					params: action.payload.params,
					query: action.payload.query
				};
				state.lang = action.payload.params?.language ?? state.lang;
				window.history.pushState(
					{},
					null,
					routeUrl(
						action.payload.route,
						action.payload.params,
						action.payload.query
					)
				);
				window.dispatchEvent(new CustomEvent('route'));
			}
		},
		setStatus: (state, action) => {
			state = Object.assign(state, action.payload);
		},
		setLang: (state, action) => {
			state.lang = action.payload.lang;
		},
		setCurrentContentLanguage: (state, action) => {
			console.log(action);
			state.currentContentLanguage = action.payload;
		},
		setFormChanged: (state, action) => {
			state.formChanged = action.payload;
		},
		setAccountDropdownState: (state, action) => {
			state.isAccountDropdownOpened = action.payload;
			state.isAdminDropdownOpened = false;
			state.isProfileDropdownOpened = false;
			state.isArtworksDropdownOpened = false;
		},
		setAdminDropdownState: (state, action) => {
			state.isAdminDropdownOpened = action.payload;
			state.isAccountDropdownOpened = false;
			state.isProfileDropdownOpened = false;
			state.isArtworksDropdownOpened = false;
		},
		setProfileDropdownState: (state, action) => {
			state.isProfileDropdownOpened = action.payload;
			state.isAccountDropdownOpened = false;
			state.isAdminDropdownOpened = false;
			state.isArtworksDropdownOpened = false;
		},
		setArtworksDropdownState: (state, action) => {
			state.isArtworksDropdownOpened = action.payload;
			state.isAccountDropdownOpened = false;
			state.isAdminDropdownOpened = false;
			state.isProfileDropdownOpened = false;
		},
		showMessage: (state, action) => {
			state.message = action.payload.message;
			state.messageType = action.payload.messageType;
			state.showMessage = true;
		},
		hideMessage: (state) => {
			state.showMessage = false;
			state.message = null;
			state.messageType = null;
		}
	}
});

export const {
	openDialog,
	closeDialog,
	setFormChanged,
	setCurrentContentLanguage,
	navigateTo,
	setStatus,
	setLang,
	setAccountDropdownState,
	setAdminDropdownState,
	setProfileDropdownState,
	setArtworksDropdownState,
	showMessage,
	hideMessage
} = statusSlice.actions;

export default statusSlice.reducer;
