import {html} from 'lit';
import {customElement} from 'lit/decorators/custom-element.js';
import {classMap} from 'lit/directives/class-map.js';
import {when} from 'lit/directives/when.js';

import style from '../../../../../scss/select.scss';
import {FormElement} from './formElement';
import {isArray} from '../../../../cdlx/utils/types';

@customElement('arc-select')
export class Select extends FormElement {

	static properties = {
		...FormElement.properties,
		options: {type: Array}, // options to select from
		emptyOption: {type: Object}, // add an empty option?
		multiple: {type: Boolean}, // can select multiple options?
	};

	static styles = [...FormElement.styles, style];

	initialValue = '';
	emptyOption = null;

	stateChanged(state) {
		super.stateChanged(state);
	}

	handleValueChange(e) {
		const input = e.target;
		let value;
		if (this.multiple) {
			value = Array.from(input.selectedOptions).map(option => option.value);
		} else {
			value = e.target.value;
		}
		this.dispatchValueChange(value);
	}
	isSelected(option) {
		if (this.multiple) {
			return this.value.includes(option.id) || this.value.includes(option.id + '');
		} else {
			return this.value + '' === option.id + '';
		}
	}

	render() {
		if (!this.edit) {
			return html`
				<div>
					<div class="textInputInfo">
						<div class="title">${this.title}</div>
					</div>
					<p class="value">${this.value}</p>
				</div>
			`;
		}
		return html`
			<div class="formField ${classMap({errors: this.hasErrors(), changed: this.inputHasChanged()})}">
				<div class="dropdownContainer">
					${when(this.title, () => html`
						<div class="textInputInfo">
							<div class="title">
								${this.title} ${when(this.required, () => html`<span class="requiredMark">*</span>`)}
							</div>
							${when(this.tooltip, () => html`
								<arc-tooltip text=${this.tooltip}></arc-tooltip>`)}
						</div>
					`)}
					${this.icon ? html`<span class="icon"><slot name="icon"></slot></span>` : ''}
					<select
						class="dropdown-select"
						.multiple=${this.multiple}
						@change=${(this.handleValueChange)}
					>
						${when(this.emptyOption !== null, () => html`
							<option value="${this.emptyOption}"></option>`)}
						${this.options.map(option => (option.counter !== undefined ?
							html`
								<option value=${option.id} .selected=${this.isSelected(option)} .disabled=${option.counter === 0}>
									${isArray(option.name) ? (option.name.find(name => name.lang === this.lang)?.value || option.name[0].value) : option.name}
									<span class="counter">(${option.counter})</span>
								</option>
							` :
							html`
								<option value=${option.id} .selected=${this.isSelected(option)}>
									${isArray(option.name) ? (option.name.find(name => name.lang === this.lang)?.value || option.name[0].value) : option.name}
								</option>`))}

					</select>
				</div>
				${when(this.hasErrors(), () => html`
					<arc-errors .errors=${this.errors}></arc-errors>`)}
			</div>
    `;
	}
}
