import {html, css, LitElement} from 'lit';
import {customElement} from 'lit/decorators/custom-element.js';
import {classMap} from 'lit/directives/class-map.js';
import {when} from 'lit/directives/when.js';
import {msg} from '@lit/localize';
import {FormElement} from './formElement';
import style from '../../../../../scss/date.scss';
import {dateToYear, yearToDate} from '../../../util/yearDateTransform';

@customElement('arc-date')
export class ArcDate extends FormElement {

	static properties = {
		...FormElement.properties,
		placeholder: {type: String},
		fullDate: {type: Boolean},
		granularity: {type: String} // day, year, all
	};

	static styles = [...FormElement.styles, style];

	//current year as string
	initialValue = '0000-00-00';

	constructor() {
		super();
		this.granularity = 'day';
		this.fullDate = this.granularity === 'day';
	}
	stateChanged(state) {
		super.stateChanged(state);
		if (this.value === '' || this.value === null) {
			this.value = this.initialValue;
		}
		this.fullDate = this.granularity === 'day';
		if (this.granularity === 'all') {
			if (this.value.substr(-2) === '00') {
				this.fullDate = false;
			} else {
				this.fullDate = true;
			}
		}
	}

	handleValueChange(e) {
		if (this.fullDate) {
			this.dispatchValueChange(e.target.value);
		} else {
			this.dispatchValueChange(yearToDate(e.target.value));
		}
	}
	toggleGranularity() {
		this.setGranularity(!this.fullDate);
	}
	setGranularity(fullDate) {
		this.fullDate = fullDate;
		if (this.fullDate) {
			this.value = this.value.substr(0, 4) + '-01-01';
		} else {
			this.value = dateToYear(this.value) + '-00-00';
		}
		this.dispatchValueChange(this.value);
	}
	render() {
		if (!this.edit) {
			return html`
				<div>
					<div class="textInputInfo">
						<div class="title">${this.title}</div>
						${when(this.tooltip, () => html`<arc-tooltip text=${this.tooltip}></arc-tooltip>`)}
					</div>
					<p class="value">${this.fullDate ? this.value : dateToYear(this.value)}</p>
				</div>
			`;
		}

		return html`
			<div class="formField ${classMap({errors: this.hasErrors(), changed: this.inputHasChanged()})}">
				<div class="textInputInfo">
					<div class="title">
						${this.title}
						${when(this.required, () => html`<span class="requiredMark">*</span>`)}
					</div>
					${when(this.granularity === 'all', () => html`
						<button class="granularityToggle ${classMap({active: this.fullDate})}" @click=${() => this.setGranularity(true)}>${msg('exact date')}</button>
						<button class="granularityToggle ${classMap({active: !this.fullDate})}" @click=${() => this.setGranularity(false)}>${msg('year only')}</button>
					`)}
					${this.icon ? html`<span class="icon"><slot name="icon"></slot></span>` : ''}
				</div>
				${when(this.fullDate, () => html`
						<input
							type="date"
							required="${this.required}"
							.value=${this.value}
							@input=${this.handleValueChange}
						/>`,
					() => html`
						<input
							type="text"
							pattern="[0-9]{4}|^$"
							required="${this.required}"
							.value=${this.value?.substr(0, 4) ?? ''}
							@input=${this.handleValueChange}
						/>
					`)
				}
				${when(this.hasErrors(), () => html`<arc-errors .errors=${this.errors}></arc-errors>`)}
			</div>
		`;
	}
}
