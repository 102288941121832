import {getProfile} from '../slices/profileSlice';
import {setCurrentContentLanguage} from '../slices/statusSlice';
import {selectProfileDefaultLanguage} from '../selectors';

export const profileMiddleware = store => next => async action => {
	const result = next(action);

	//load full profile data after setCurrentProfileId
	if (action.type === 'profile/setCurrentProfileId') {
		store.dispatch(getProfile({id: action.payload}));
	}
	console.log('profileMiddleware', action.type);

	if (action.type === 'profile/get/fulfilled') {
		const state = store.getState();
		// set profile default language to current content language in status
		store.dispatch(setCurrentContentLanguage(
			selectProfileDefaultLanguage(state)
		));
	}
	return result;
  };
