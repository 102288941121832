import {connect} from 'pwa-helpers';
import {LitElement, html} from 'lit';
import {customElement} from 'lit/decorators/custom-element.js';
import {when} from 'lit/directives/when.js';
import {classMap} from 'lit/directives/class-map.js';

import {msg} from '@lit/localize';
import {navigator, router} from 'lit-element-router';
import {repeat} from 'lit/directives/repeat.js';
import deepEqual from 'deep-equal';

import store from '../../store';
import {routeUrl} from '../../util/routeUrl';
import getRoutes from '../../routes';
import style from '../../../../scss/artistProfile.scss';
import formPageStyle from '../../../../scss/formPage.scss';
import linkstyle from '../../../../scss/link.scss';
import loadingSpinnerStyle from '../../../../scss/loadingSpinner.scss';
import arrowRight from '../../icons/arrowRight';

import {
	selectProfileById,
	updateProfile,
	getProfile,
	uploadImage,
	removeImage
} from '../../slices/profileSlice';
import {profileDisplayName} from '../../util/profileDisplayName';
import {getValueForLanguage} from '../../util/getValueForLanguage';
import {dateToYear} from '../../util/yearDateTransform';
import {deepClone} from '../../util/deepClone';
import {selectProfileDefaultLanguage} from '../../selectors';
import {navigateTo, setFormChanged} from '../../slices/statusSlice';

@customElement('arc-artist-profile')
export class ArtistProfile extends connect(store)(router(navigator(LitElement))) {
	static styles = [style, formPageStyle, linkstyle, loadingSpinnerStyle];

	static properties = {
		localFormData: {type: Object, hasChanged: (n, o) => !deepEqual(n, o)},
		errors: {type: Object,  hasChanged: (n, o) => !deepEqual(n, o)},
		edit: {type: Boolean}
	};

	constructor() {
		super();
		this.updateNeeded = false;
		this.localFormData = null;
		this.errors = null;
		this.isLoading = false;
		this.currentLanguage = 'en';
		this.componentActive = true;

		store.subscribe(() => this.requestUpdate());
	}

	stateChanged(state) {
		this.state = state;
		this.status = state.status;
		this.formChanged = state.status.formChanged;
		this.errors = state.profile.error;
		this.isLoading = state.profile.loading === 'loading';
		this.currentLanguage = selectProfileDefaultLanguage(state);
	}

	static get routes() {
		return getRoutes();
	}

	async router(route, params, query, data) {
		this.params = params;

		this.edit = true; // params.mode === 'edit';

		this.updateNeeded = route === 'artist' && this.profileId !== params.id;

		this.profileId = params.id;

		this.requestUpdate();
	}

	update(changedProperties) {
		super.update(changedProperties);

		// fetch new artwork data if needed, but only once
		// TODO: remove componentActive check
		if (this.updateNeeded && this.componentActive) {
			this.fetchData();
		}
	}

	async fetchData() {
		this.updateNeeded = false;

		await store.dispatch(getProfile({id: this.profileId})); // fetch profile from backend
		// clone data for local editing (component state)
		this.localFormData = selectProfileById(this.state, this.profileId);
	}
	handleValueChange(fieldData, value) {
		const section = fieldData.section;
		const field = fieldData.field;

		// update localFormData with new value in section and field
		const newData = deepClone(this.localFormData); // clone current date

		// checkbox: convert string to number if it is a integer
		if (fieldData.type === 'checkbox' && typeof value === 'string' && value.match(/^\d+$/)) {
			value = parseInt(value, 10);
		}

		if (section) {
			if (newData[section]?.[field]) {
				newData[section][field] = value; // update field
			} else {
				newData[section] = {...newData[section], [field]: value}; // create new field
			}
		} else {
			newData[field] = value; // update field
		}
		// also update display name, derived from first name, last name, pen name
		newData.displayName = profileDisplayName(newData);

		this.localFormData = newData; // assign (updates reference, triggers update)

		store.dispatch(setFormChanged(this.formHasChanged()));
	}

	// saveProfile: Edit user profile data, send it to API
	async saveProfile() {
		await store.dispatch(updateProfile(this.localFormData));
		store.dispatch(setFormChanged(false));
	}

	cancelEditing() {
		// cancel editing and go back to preview
		store.dispatch(navigateTo({route: 'artist', params: {...this.params, mode: 'preview'}}));
	}

	editProfile() {
		// go back to editing
		store.dispatch(navigateTo({route: 'artist', params: {...this.params, mode: 'edit'}}));
	}

	async addFile(file) {
		await store.dispatch(uploadImage({profileId: this.profileId, file: file}));
		this.localFormData = deepClone(selectProfileById(this.state, this.profileId));
	}

	async removeFile(fileId) {
		await store.dispatch(removeImage({profileId: this.profileId, imageId: fileId}));
		this.localFormData = deepClone(selectProfileById(this.state, this.profileId));
	}


	connectedCallback() {
		super.connectedCallback();
		store.dispatch(setFormChanged(false));
	}

	disconnectedCallback() {
		super.disconnectedCallback();
		this.componentActive = false;
		store.dispatch(setFormChanged(false));
	}

	formHasChanged() {
		if (!this.localFormData) return false;

		// check if local form data is different
		const newData = deepClone(this.localFormData);
		const oldData = deepClone(selectProfileById(this.state, this.profileId));
		delete newData.modified;
		delete oldData.modified;
		return !deepEqual(newData, oldData);
	}

	render() {
		//loading message before the form loads with data
		if (this.isLoading || !this.localFormData) {
			return html`
				<div class="loading-overlay">
					<div class="spinner"></div>
				</div>
			`;
		}

		const singleInputFields = [
			{
				type: 'textInput',
				title: msg('First Name'),
				field: 'firstName',
				section: 'singleInfo',
				placeholder: '',
				data: this.localFormData,
				errors: this.errors,
				required: true,
				handler: this.handleValueChange.bind(this)
			},
			{
				type: 'checkbox',
				title: msg('Is artist group'),
				field: 'isGroup',
				section: null,
				tooltip: '',
				data: this.localFormData,
				errors: this.errors,
				handler: this.handleValueChange.bind(this)
			},
			{
				type: 'textInput',
				title: msg('Last Name'),
				field: 'lastName',
				section: 'singleInfo',
				placeholder: '',
				data: this.localFormData,
				errors: this.errors,
				required: true,
				handler: this.handleValueChange.bind(this)
			},
			{
				type: 'textInput',
				title: msg('Pen Name'),
				field: 'penName',
				section: 'singleInfo',
				placeholder: '',
				data: this.localFormData,
				errors: this.errors,
				handler: this.handleValueChange.bind(this)
			},
			{
				type: 'multilingualTextInput',
				title: msg('Title'),
				field: 'title',
				section: 'singleInfo',
				placeholder: '',
				data: this.localFormData,
				errors: this.errors,
				required: false,
				handler: this.handleValueChange.bind(this)
			},
			{
				type: 'multilingualTextInput',
				title: msg('Gender'),
				field: 'gender',
				section: 'singleInfo',
				placeholder: '',
				data: this.localFormData,
				errors: this.errors,
				handler: this.handleValueChange.bind(this)
			},
			{
				type: 'multilingualTextInput',
				title: msg('Nationality'),
				field: 'nationality',
				section: 'singleInfo',
				placeholder: '',
				data: this.localFormData,
				errors: this.errors,
				handler: this.handleValueChange.bind(this)
			},
			{
				type: 'date',
				title: msg('Date of birth'),
				field: 'birthday',
				section: 'singleInfo',
				granularity: 'day',
				data: this.localFormData,
				errors: this.errors,
				required: false,
				handler: this.handleValueChange.bind(this)
			},
			{
				type: 'textInput',
				title: msg('Birth Location'),
				field: 'birthLocation',
				section: 'singleInfo',
				placeholder: '',
				data: this.localFormData,
				errors: this.errors,
				required: false,
				handler: this.handleValueChange.bind(this)
			},
			{
				type: 'multilingualTextInput',
				title: msg('Bio'),
				field: 'bio',
				section: 'singleInfo',
				placeholder: '',
				data: this.localFormData,
				multiline: true,
				errors: this.errors,
				tooltip: msg('shortbio_text'),
				handler: this.handleValueChange.bind(this)
			}
		];

		const groupInputFields = [
			{
				type: 'textInput',
				title: msg('Name'),
				field: 'name',
				section: 'groupInfo',
				placeholder: '',
				data: this.localFormData,
				errors: this.errors,
				required: true,
				handler: this.handleValueChange.bind(this)
			},
			{
				type: 'checkbox',
				title: msg('Is artist group'),
				field: 'isGroup',
				section: null,
				tooltip: '',
				data: this.localFormData,
				errors: this.errors,
				handler: this.handleValueChange.bind(this)
			},
			{
				type: 'textInput',
				title: msg('Location'),
				field: 'location',
				section: 'groupInfo',
				placeholder: '',
				data: this.localFormData,
				errors: this.errors,
				required: false,
				handler: this.handleValueChange.bind(this)
			},
			{
				type: 'multilingualTextInput',
				title: msg('Duration of Collaboration'),
				field: 'duration',
				section: 'groupInfo',
				placeholder: '',
				data: this.localFormData,
				errors: this.errors,
				required: false,
				handler: this.handleValueChange.bind(this)
			},
			{
				type: 'multilingualTextInput',
				title: msg('Short Description'),
				field: 'bio',
				section: 'groupInfo',
				placeholder: '',
				data: this.localFormData,
				errors: this.errors,
				multiline: true,
				handler: this.handleValueChange.bind(this)
			}
		];

		const contactInputFields = [
			{
				type: 'textInput',
				title: msg('Tel'),
				field: 'phone',
				section: 'contacts',
				placeholder: '',
				data: this.localFormData,
				errors: this.errors,
				required: false,
				handler: this.handleValueChange.bind(this)
			},
			{
				type: 'textInput',
				title: msg('Email'),
				field: 'email',
				section: 'contacts',
				placeholder: '',
				data: this.localFormData,
				errors: this.errors,
				required: false,
				handler: this.handleValueChange.bind(this)
			},
			{
				type: 'textInput',
				title: msg('Website'),
				field: 'website',
				section: 'contacts',
				placeholder: '',
				data: this.localFormData,
				errors: this.errors,
				required: false,
				handler: this.handleValueChange.bind(this)
			},
			{
				type: 'textInput',
				title: msg('Address'),
				field: 'address1',
				section: 'contacts',
				placeholder: '',
				data: this.localFormData,
				errors: this.errors,
				handler: this.handleValueChange.bind(this)
			},
			{
				type: 'textInput',
				title: msg('Address'),
				field: 'address2',
				section: 'contacts',
				placeholder: '',
				data: this.localFormData,
				errors: this.errors,
				handler: this.handleValueChange.bind(this)
			},
			{
				type: 'textInput',
				title: msg('City'),
				field: 'city',
				section: 'contacts',
				placeholder: '',
				data: this.localFormData,
				errors: this.errors,
				handler: this.handleValueChange.bind(this)
			},
			{
				type: 'textInput',
				title: msg('Zipcode'),
				field: 'zipCode',
				section: 'contacts',
				placeholder: '',
				data: this.localFormData,
				errors: this.errors,
				handler: this.handleValueChange.bind(this)
			},
			{
				type: 'textInput',
				title: msg('Country'),
				field: 'country',
				section: 'contacts',
				placeholder: '',
				data: this.localFormData,
				errors: this.errors,
				handler: this.handleValueChange.bind(this)
			}
		];

		return html`
			<div class="formPage">
				<arc-toolbar class="toolbar">
					<div class="leftSlot" slot="left">
						<arc-breadcrumbs>
							<span>${msg('Profile')}</span>
						</arc-breadcrumbs>
					</div>
					<div class="rightSlot" slot="right">
						<arc-button
							title="${msg('Save Profile')}"
							type="primary"
							.additionalInfo="${false}"
							?disabled="${!this.formChanged}"
							class="saveProfileButton ${classMap({pending: this.isLoading})}"
							@click=${this.saveProfile}
						></arc-button>
					</div>
				</arc-toolbar>
				<header>
					<h2 class="pageTitle">${profileDisplayName(this.localFormData)}</h2>
					<arc-content-language-switch></arc-content-language-switch>
				</header>

				<section>
					<div class="leftColumn">
						<h2 class="sectionTitle">${msg('Basic Information')}</h2>

						${when(
							this.localFormData?.isGroup,
							() => html`
								<arc-form-grid
									class="groupInfo"
									.fields=${groupInputFields}
									.localFormData=${this.localFormData}
									.errors=${this.errors}
									.edit=${this.edit}
								>
								</arc-form-grid>
							`,
							() => html`
								<arc-form-grid
									class="singleInfo"
									.fields=${singleInputFields}
									.localFormData=${this.localFormData}
									.errors=${this.errors}
									.edit=${this.edit}
								>
								</arc-form-grid>
							`
						)}
					</div>
					<div class="rightColumn">
						<div class="profileImage">
							<div class="imagePreview">
								<arc-image .file=${this.localFormData?.mainImage} alt="Main Image"></arc-image>
							</div>
							<arc-image-upload
								addLabel=${msg('Add Profile Image')}
								removeLabel=${msg('Remove Profile Image')}
								acceptedFormats="image/*"
								fileId=${this.localFormData?.mainImage?.id}
								.edit=${this.edit}
								@add-file=${(e) => this.addFile(e.detail.file)}
								@remove-file=${(e) => this.removeFile(e.detail.fileId)}
							></arc-image-upload>
						</div>
					</div>
				</section>

				<section>
					<div class="leftColumn">
						<h2 class="sectionTitle">${msg('Contact')}</h2>

						<arc-form-grid
							class="contactInfo"
							.fields=${contactInputFields}
							.localFormData=${this.localFormData}
							.errors=${this.errors}
							.edit=${this.edit}
						>
						</arc-form-grid>
					</div>
					<div class="rightColumn"></div>
				</section>

				<section>
					<div class="leftColumn">
						<div class="titleWithLink">
							<h2 class="sectionTitle">${msg('Curriculum Vitae')}</h2>
							<arc-routerlink
								route="artist-cv"
								.params=${{id: this.profileId, mode: 'edit'}}
								class="editCVLink"
								.icon=${arrowRight}
							>${msg('Edit CVs')}
							</arc-routerlink>
						</div>

						${repeat(
							this.localFormData?.cv,
							(section, index) => index,
							(section, index) => (
								section.entries && section.entries.length > 0
									? html`
										<div class="cvSectionTitle">
											${getValueForLanguage(section.sectionName, this.currentLanguage, true)}
										</div>
										<div class="cvEntries">
											${repeat(
												section.entries,
												(entry) => entry.id,
												(entry) => html`
													<arc-artist-cv-item class='cvItem' .entry=${entry}></arc-artist-cv-item>
												`
											)}
										</div>
									`
									: '')
						)}
					</div>
					<div class="rightColumn"></div>
				</section>
			</div>

		`;
	}
}
