import {connect} from 'pwa-helpers';
import {LitElement, html} from 'lit';
import {customElement} from 'lit/decorators.js';
import {when} from 'lit/directives/when.js';
import {msg} from '@lit/localize';
import {navigator, router} from 'lit-element-router';
import {repeat} from 'lit/directives/repeat.js';
import deepEqual from 'deep-equal';
import {deepClone} from '../../util/deepClone';

import getRoutes from '../../routes';
import store from '../../store';
import style from '../../../../scss/artworkMedia.scss';
import loadingSpinnerStyle from '../../../../scss/loadingSpinner.scss';
import {
	fetchMediaCollection,
	selectAllMedia,
	massUpdateMedia,
	selectArtworkById,
	fetchArtwork,
	createMedia, deleteMedia, replaceMedia
} from '../../slices/artworkSlice';
import {getValueForLanguage} from '../../util/getValueForLanguage';
import {selectProfileDefaultLanguage, selectLanguage} from '../../selectors';
import arrowDown from '../../icons/arrowDown';
import arrowUp from '../../icons/arrowUp';
import {setFormChanged} from '../../slices/statusSlice';
import formPageStyle from '../../../../scss/formPage.scss';

@customElement('arc-artwork-media')
export class ArtworkMedia extends connect(store)(router(navigator(LitElement))) {
	static styles = [formPageStyle, loadingSpinnerStyle, style];

	static properties = {
		mediaCollection: {type: Array},
		localFormData: {type: Object, hasChanged: (n, o) => !deepEqual(n, o)},
		artworkId: {type: String},
		isLoading: {type: Boolean},
		edit: {type: Boolean},
		artwork: {type: Object},
		parentArtwork: {type: Object},
		formChanged: {type: Boolean}
	};

	constructor() {
		super();
		this.mediaCollection = [];
		this.localFormData = [];

		this.artworkId = null;
		this.isLoading = true;
		this.edit = true;
		this.currentLanguage = 'en';
		this.artwork = null;
		this.parentArtwork = null;
	}

	stateChanged(state) {
		this.state = state;
		this.mediaCollection = selectAllMedia(state);
		this.isLoading = state.artwork.loading === 'loading';
		this.artwork = selectArtworkById(this.state, this.artworkId);
		this.currentLanguage = selectProfileDefaultLanguage(state);
		this.lang = selectLanguage(state);
		this.formChanged = state.status.formChanged;
	}

	static get routes() {
		return getRoutes();
	}

	async router(route, params) {
		this.edit = true; // params.mode === 'edit';
		this.profileId = params.id;
		if (route === 'artwork-media' && String(this.artworkId) !== params.artworkId) {
			this.artworkId = parseInt(params.artworkId, 10);
			await store.dispatch(fetchMediaCollection({artworkId: this.artworkId}));
			this.localFormData = selectAllMedia(this.state, this.artworkId);

			await store.dispatch(fetchArtwork({id: this.artworkId}));
			if (this.artwork.parentId) {
				store.dispatch(fetchArtwork({id: this.artwork.parentId}));
			}
		}
	}

	connectedCallback() {
		super.connectedCallback();

		// reset form changed status to allow navigation
		store.dispatch(setFormChanged(false));
	}

	async addFile(file) {
		if (this.formChanged) {
			await this.saveAllMedia(); // save current data before adding new one and updating collection
		}
		await store.dispatch(createMedia({artworkId: this.artworkId, file: file}));
		this.localFormData = selectAllMedia(this.state, this.artworkId);
	}

	async handleRemoveFile(fileId) {
		if (this.formChanged) {
			await this.saveAllMedia(); // save current data before adding new one and updating collection
		}
		await store.dispatch(deleteMedia({artworkId: this.artworkId, fileId: fileId}));
		this.localFormData = selectAllMedia(this.state, this.artworkId);
	}

	async handleReplaceFile(id, file) {
		if (file) {
			if (this.formChanged) {
				await this.saveAllMedia(); // save current data before adding new one and updating collection
			}
			await store.dispatch(
				replaceMedia({
					artworkId: this.artworkId,
					mediaId: id,
					file: file
				})
			);
			this.localFormData = selectAllMedia(this.state, this.artworkId);
		}
	}

	moveMedia(index, direction) {
		const updatedCollection = deepClone(this.localFormData);

		//swap position values
		if (direction === 'up' && index > 0) {
			const tempPos = updatedCollection[index].pos;
			updatedCollection[index].pos = updatedCollection[index - 1].pos;
			updatedCollection[index - 1].pos = tempPos;
		} else if (direction === 'down' && index < updatedCollection.length - 1) {
			const tempPos = updatedCollection[index].pos;
			updatedCollection[index].pos = updatedCollection[index + 1].pos;
			updatedCollection[index + 1].pos = tempPos;
		}
		this.localFormData = updatedCollection;
		store.dispatch(setFormChanged(this.formHasChanged()));
	}

	async saveAllMedia() {
		const mediaItems = this.localFormData.map((item) => {
			const description = (item.info.description || []).filter(
				(entry) => entry.value.trim() !== ''
			);
			const alt = (item.info.alt || []).filter((entry) => entry.value.trim() !== '');
			const artworkCopyright = (item.info.artworkCopyright || []).filter(
				(entry) => entry.value.trim() !== ''
			);
			const mediaCopyright = (item.info.mediaCopyright || []).filter(
				(entry) => entry.value.trim() !== ''
			);

			const filteredItem = {
				id: item.id,
				visibility: item.visibility || 'private',
				title: item.info.title,
				description: description,
				alt: alt,
				artworkCopyright: artworkCopyright,
				mediaCopyright: mediaCopyright
			};

			['description', 'alt', 'artworkCopyright', 'mediaCopyright'].forEach((field) => {
				if (filteredItem[field].length === 0) {
					delete filteredItem[field];
				}
			});

			return filteredItem;
		});
		await store.dispatch(massUpdateMedia({artworkId: this.artworkId, items: mediaItems}));
		store.dispatch(setFormChanged(false));
	}

	handleItemChange(index, value) {
		const newData = deepClone(this.localFormData); // clone current date
		newData[index] = value;
		this.localFormData = newData; // assign (updates reference, triggers update)
		store.dispatch(setFormChanged(this.formHasChanged()));
	}

	formHasChanged() {
		// check if local form data is different
		const newData = deepClone(this.localFormData ?? null);
		const oldData = deepClone(selectAllMedia(this.state, this.artworkId) ?? null);
		return !deepEqual(newData, oldData);
	}

	render() {
		const title = this.artwork
			? getValueForLanguage(this.artwork.title, this.currentLanguage, true)
			: null;

		const sortedMediaCollection = deepClone(this.localFormData).sort((a, b) => a.pos - b.pos);

		return html`
			<div class="formPage artworkMedia">
				<arc-toolbar class="toolbar">
					<div slot="left">
						<arc-breadcrumbs>
							<arc-routerlink route="artworks">${msg('Artworks')}</arc-routerlink>
							${when(
								this.parentArtwork,
								() => html`
									<arc-routerlink
										route="artwork"
										.params=${{id: this.profileId, artworkId: this.parentArtwork.id, mode: 'edit'}}
									>
										${getValueForLanguage(this.parentArtwork.title, this.currentLanguage, true)}
									</arc-routerlink>`
							)}
							<arc-routerlink
								route="artwork"
								.params=${{id: this.profileId, artworkId: this.artworkId, mode: 'edit'}}
							>
								${title}
							</arc-routerlink>
							<span>${msg('Media')}</span>
						</arc-breadcrumbs>
					</div>
					<div slot="right">
						<arc-image-upload
							addLabel="${msg('Add Media')}"
							acceptedFormats="image/*,video/*,application/pdf"
							.edit=${this.edit}
							@add-file=${(e) => this.addFile(e.detail.file)}
						></arc-image-upload>
						<arc-button
							title="${msg('Save Media')}"
							type="primary"
							?disabled=${!this.formChanged}
							@click=${this.saveAllMedia}
						></arc-button>
					</div>
				</arc-toolbar>

				<header>
					<h2 class="pageTitle">${msg('Edit Media')}</h2>
					<arc-content-language-switch></arc-content-language-switch>
					<p>
						${msg('Valid formats')}: png / jpeg / mp4 / mov / pdf<br />
						${msg('maximum size')}: 50 MB
					</p>
				</header>


				${this.localFormData.length > 0
					? html`
						<div class="mediaList">
							${repeat(
								sortedMediaCollection,
								(media) => media.id,
								(media, index) => html`
									<div class="mediaItem">
										<arc-artwork-media-item
											.index=${index}
											.value=${media}
											.edit=${this.edit}
											.artworkId=${this.artworkId}
											@value-change=${(e) => this.handleItemChange(e.detail.index, e.detail.value)}
											@remove-file=${(e) => this.handleRemoveFile(e.detail.fileId)}
											@replace-file=${(e) => this.handleReplaceFile(e.detail.fileId, e.detail.file)}
										></arc-artwork-media-item>
										<div class="arrowControls">
											<button
												?disabled=${index === 0}
												class="${index === 0 ? 'disabledArrow' : ''}"
												@click=${() => this.moveMedia(index, 'up')}
											>
												${arrowUp}
											</button>
											<button
												?disabled=${index === this.localFormData.length - 1}
												class="${index === this.localFormData.length - 1
													? 'disabledArrow'
													: ''}"
												@click=${() => this.moveMedia(index, 'down')}
											>
												${arrowDown}
											</button>
										</div>
									</div>
								`
							)}
						</div>
					`
					: html`<p>${msg('No media available for this artwork.')}</p>`}
			</div>
		`;
	}
}
