import {connect} from 'pwa-helpers';
import {LitElement, html} from 'lit';
import {customElement} from 'lit/decorators/custom-element.js';
import {msg, str} from '@lit/localize';
import {when} from 'lit/directives/when.js';
import {navigator, router} from 'lit-element-router';
import Cookies from 'js-cookie';

import store from '../../store';

import style from '../../../../scss/login.scss';
import {loginWithEmail, setLoginEmail} from '../../slices/accountSlice';
import {routeUrl} from '../../util/routeUrl';
import getRoutes from '../../routes';
import {navigateTo, showMessage} from '../../slices/statusSlice';

@customElement('arc-request-token')
export class RequestToken extends connect(store)(router(navigator(LitElement))) {
	static styles = [style];

	emailForRequest = '';
	devLoginToken = null;

	constructor() {
		super();

		store.subscribe(() => this.requestUpdate());
	}

	static get routes() {
		return getRoutes();
	}
	router(route, params, query, data) {
		this.route = route;
		this.params = params;
		this.query = query;
	}

	stateChanged(state) {
		this.status = state.status;
		this.emailForRequest = state.account.data.loginEmail || '';
		this.accountData = state.account.data.account;
		this.accountStatus = state.account.status;

		this.devLoginToken = state.account.data.devLoginToken;
	}

	async connectedCallback() {
		super.connectedCallback();
		const email = Cookies.get('arc-email');
		if (email) {
			store.dispatch(setLoginEmail({loginEmail: email}));
		}
	}

	disconnectedCallback() {
		super.disconnectedCallback();
	}

	onEmailForRequestInput(value) {
		store.dispatch(setLoginEmail({loginEmail: value}));
	}
	async onRequestEmail() {
		await store.dispatch(loginWithEmail({email: this.emailForRequest}));
		await store.dispatch(showMessage({
			message: msg(str`login code sent to ${this.emailForRequest}`),
			messageType: 'success',
		}));
		store.dispatch(navigateTo({route: 'login', params: this.params}));
	}

	render() {
		const state = store.getState();

		return html`
			<div class="login">
				<h1>${msg('Request Code')}</h1>
				<p>
					${msg('request login code description')}
				</p>
				<arc-text-input
					title="${msg('E-Mail')}"
					placeholder="${msg('Enter Email')}"
					.value=${this.emailForRequest}
					@value-change=${(e) => this.onEmailForRequestInput(e.detail.value)}>
				</arc-text-input>

				<arc-button
					@click=${this.onRequestEmail}
					title="${msg('Request Login Code')}"
					type="primary"
					.additionalInfo="${false}">
				</arc-button>

				${when(
					this.devLoginToken,
					() => html`
						<p>Login Token (for devs): ${this.devLoginToken}</p>
						<arc-routerlink route="login">${msg('Login')}</arc-routerlink>
					`
				)}
			</div>
		`;
	}
}
