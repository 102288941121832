import {connect} from 'pwa-helpers';
import {LitElement, html} from 'lit';
import {customElement} from 'lit/decorators/custom-element.js';
import {msg} from '@lit/localize';
import {when} from 'lit/directives/when.js';
import {router, navigator} from 'lit-element-router';

import store from '../../store';
import style from '../../../../scss/login.scss';
import {authenticateWithToken} from '../../slices/accountSlice';
import getRoutes from '../../routes';
import {navigateTo} from '../../slices/statusSlice';

@customElement('arc-login')
export class Login extends connect(store)(router(navigator(LitElement))) {
	static styles = [style];

	static properties = {
		email: {type: String},
		loginToken: {type: String},
		loginEmail: {type: String},
		consent: {type: Boolean},
		loginSession: {type: Boolean}
	};

	devLoginToken = null;

	constructor() {
		super();
		store.subscribe(() => this.requestUpdate());
	}

	static get routes() {
		return getRoutes();
	}
	router(route, params, query, data) {
		this.route = route;
		this.params = params;
		this.query = query;
	}

	stateChanged(state) {
		this.email = state.account.data.loginEmail || '';
		this.loginToken = '';
		this.consent = false;
		this.loginSession = false;

		this.accountData = state.account.data.account;
		this.accountStatus = state.account.status;
		this.loggedIn = state.account.data.loggedIn;
		this.account = state.account.data.account;

		this.devLoginToken = state.account.data.devLoginToken;
		this.loginEmail = state.account.data.loginEmail;
		this.loggedIn = state.account.data.loggedIn;
		this.inputErrors = state.account.data.errors?.input?.params;
	}

	async connectedCallback() {
		super.connectedCallback();
	}

	disconnectedCallback() {
		super.disconnectedCallback();
	}

	// handle Inputs
	onEmailInput(input) {
		this.email = input;
	}
	onLoginTokenInput(input) {
		this.loginToken = input;
	}
	onConsent(checked) {
		this.consent = checked;
	}
	onLongSession(checked) {
		this.longSession = checked;
	}
	onRequestCode() {
		store.dispatch(navigateTo({route: 'requestToken', params: this.params}));
	}
	isLoginPossible() {
		return this.email !== '' && this.loginToken !== '' && this.consent;
	}

	onAuthenticate(input) {
		store.dispatch(
			authenticateWithToken({
				email: this.email,
				token: this.loginToken,
				consent: this.consent,
				longSession: this.loginSession,
			})
		);
	}

	render(e) {
		const state = store.getState();

		return html`
			${when(
				this.loggedIn,
				() => html`
					<arc-welcome>
						<p>
							${msg('login message')}
						</p>
					</arc-welcome>
				`,
				() => html`
					<div class="login">
						<h1>${msg('Login')}</h1>
						<arc-text-input
							title="${msg('Email')}"
							placeholder="${msg('Enter Email')}"
							.value=${this.loginEmail}
							.errors=${this.inputErrors?.email?.errors}
							@value-change=${(ev) => this.onEmailInput(ev.detail.input)}
						>
						</arc-text-input>
						<arc-text-input
							title="${msg('Token')}"
							placeholder="${msg('Enter Token')}"
							.value=${''}
								.errors=${this.inputErrors?.loginToken?.errors}
							@value-change=${(ev) => this.onLoginTokenInput(ev.detail.value)}
						>
						</arc-text-input>
						<arc-checkbox
							title="${msg('Consent')}"
							.value=${false}
							.errors=${this.inputErrors?.consent?.errors}
							@value-change=${(ev) => this.onConsent(ev.detail.value)}
						>
						</arc-checkbox>
						<arc-checkbox
							title="${msg('LongSession')}"
							.value=${true}
							.errors=${this.inputErrors?.longSession?.errors}
							@value-change=${(ev) => this.onConsent(ev.detail.value)}
						>
						</arc-checkbox>

						<div class="buttons">
							<arc-button
								@click=${this.onAuthenticate}
								.disabled=${!this.isLoginPossible()}
								title="${msg('Log In')}"
								type="primary"
								.additionalInfo="${false}">
							</arc-button>
							<arc-button
								@click=${this.onRequestCode}
								title="${msg('Request code again')}"
								type="secondary"
								.additionalInfo="${false}">
							</arc-button>
						</div>
					</div>
					</div>
				`
			)}
		`;
	}
}
