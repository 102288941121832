import {html, css, LitElement} from 'lit';
import {connect} from 'pwa-helpers';
import deepEqual from 'deep-equal';

import store from '../../../store';
import {errorMessages} from '../../../util/errorMessages';
import {deepClone} from '../../../util/deepClone';

import formFieldStyle from '../../../../../scss/formField.scss';

export class FormElement extends connect(store)(LitElement) {

	static properties = {
		title: {type: String}, // title
		icon: {type: Boolean}, // icon (optional)
		value: {type: Object, hasChanged: (n, o) => !deepEqual(n, o)}, // initial value
		inputValue: {type: Object}, // value of the input field
		errors: {type: Array}, // errors of this element
		edit: {type: Boolean}, // allow editing
		changeIndication: {type: Boolean}, // show indication if value has changed
		required: {type: Boolean}, // is entry required?
		tooltip: {type: String}, // tooltip

		lang: {type: String}, // language
		index: {type: Number}, // index of the element when part of repeat
		type: {type: String} // type of data edited by form element
	};

	static styles = [formFieldStyle];

	initialValue = null;

	constructor() {
		super();
		this.index = null;
		this.type = null;
		this.icon = false;
		this.edit = true;
		this.required = false;
		this.changeIndication = true;
		this.inputValue = this.initialValue;
		this.lang = 'de';
		this.tooltip = null;
		store.subscribe(() => this.requestUpdate());
	}

	stateChanged(state) {
		this.state = state;
		this.lang = state.status.lang;
	}

	connectedCallback() {
		super.connectedCallback();
		if (this.value) {
			this.inputValue = deepClone(this.value);
		} else {
			this.inputValue = deepClone(this.initialValue);
		}
	}
	disconnectedCallback() {
		super.disconnectedCallback();
	}

	updated(changedProperties) {
		super.updated(changedProperties);

		// if there is an error in this field, dispatch event to tell collapsable section to stay open
		// section will check if it contains the error
		if (changedProperties.has('errors') && this.hasErrors()) {
			this.dispatchEvent(
				new CustomEvent('field-error', {
					bubbles: true,
					composed: true, // include component path
					detail: {errors: this.errors}
				})
			);
		}
	}

	handleValueChange(e) {
		this.dispatchValueChange(e.target.value);
	}

	dispatchValueChange(value) {
		const eventValue = {
			detail: {
				index: this.index,
				type: this.type,
				value: value
			}
		};
		// console.log('dispatchValueChange', eventValue);
		this.dispatchEvent(new CustomEvent('value-change', eventValue));
		this.requestUpdate('inputValue');
	}

	inputHasChanged() {
		// do not show empty values as changed, initials
		if ((this.inputValue === 0 || this.inputValue === '' || !this.inputValue) && (this.value === 0 || this.value === '' || !this.value)) {
			return false;
		} else {
			return this.value !== undefined && this.changeIndication && !deepEqual(this.inputValue, this.value);
		}
	}
	hasErrors() {
		return errorMessages(this.errors).length > 0 && !this.inputHasChanged();
	}
	getInitialValue() {
		return this.initialValue;
	}

	render() {

		return html`
			[override]
    `;
	}
}
