// side effect: store configuration, when decisions are made
import Cookies from 'js-cookie';
import {clearAccount, storeAccount} from '../slices/accountSlice';
import {clearCurrentProfile, getProfile, setCurrentProfileId} from '../slices/profileSlice';
import {clearArtworks} from '../slices/artworkSlice';
import {navigateTo} from '../slices/statusSlice';

export const accountMiddleware = store => next => async action => {
	const result = next(action);

	// store
	if (action.type === 'account/setEmail') {
		store.dispatch(storeAccount());
	}

	// load full main profile data after login/init
	if (action.type === 'account/initSession/fulfilled' || action.type === 'account/authenticateWithToken/fulfilled') {
		const state = store.getState();
		if (state.account.data?.loggedIn) {
			if (state.account.data?.account?.mainProfile) {
				store.dispatch(setCurrentProfileId(state.account.data?.account?.mainProfile.id));
			} else {
				// get saved profileId from cookie
				// Todo: check permissions
				const profileId = Cookies.get('arc-profile');
				if (profileId) {
					store.dispatch(setCurrentProfileId(profileId));
				}
			}
		}
	}

	// clear data after logging out
	if (action.type === 'account/logout/fulfilled') {
		store.dispatch(clearCurrentProfile());
		store.dispatch(clearArtworks());
	}

	// if any action ends with /rejected, check if it's 'Unauthorized' and redirect to login
	if (action.type.endsWith('/rejected')) {
		const state = store.getState();
		if (action.error.message === 'Unauthorized') {
			console.log('Unauthorized, redirecting to login');
			store.dispatch(clearCurrentProfile());
			store.dispatch(clearArtworks());
			store.dispatch(clearAccount());
		}
	}

	return result;
};
