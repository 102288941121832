import {connect} from 'pwa-helpers';
import {LitElement, html} from 'lit';
import {navigator, router} from 'lit-element-router';
import {customElement} from 'lit/decorators/custom-element.js';
import {repeat} from 'lit/directives/repeat.js';
import {msg} from '@lit/localize';
import deepEqual from 'deep-equal';

import store from '../../store';
import style from '../../../../scss/accountsList.scss';
import {
	selectAccountsQuery,
	selectLanguage
} from '../../selectors';
import getRoutes from '../../routes';
import arrowRight from '../../icons/arrowRight';
import arrowDown from '../../icons/arrowDown';
import arrowUp from '../../icons/arrowUp';
import down from '../../icons/down';
import up from '../../icons/up';
import {fetchAccounts, selectAllAccounts} from '../../slices/accountSlice';

@customElement('arc-account-list')
export class AccountList extends connect(store)(router(navigator(LitElement))) {
	static styles = [style];

	static properties = {};

	constructor() {
		super();
		this.currentLanguage = 'de';
		this.accounts = [];
		//this.searchText = '';
		//this.filtersVisible = false;
		store.subscribe(() => this.requestUpdate());
	}

	stateChanged(state) {
		this.state = state;
		this.status = state.status;
		this.lang = selectLanguage(state);
		this.accounts = selectAllAccounts(state);

		this.accountsQuery = selectAccountsQuery(state);
		this.totalPages = state.account.accountsPagination.pagesAmount;

		if (this.updateNeeded) {
			this.updateNeeded = false;
			console.log('fetchAccounts', this.query);
			store.dispatch(fetchAccounts({...this.query}));
		}
		this.requestUpdate();
	}

	static get routes() {
		return getRoutes();
	}

	router(route, params, query, data) {

		// do accounts need update from backend
		this.updateNeeded = route === 'admin-accounts' &&
			(!deepEqual(this.query, query));

		this.params = params;
		this.query = query;
		this.data = data;
	}

	connectedCallback() {
		super.connectedCallback();
	}

	disconnectedCallback() {
		super.disconnectedCallback();
	}

	updateSearch(event) {
		this.searchText = event.target.value.trim().toLowerCase();
	}

	getOrderQuery(orderBy) {
		return {
			...this.query,
			...{
				orderBy: orderBy,
				orderByDirection: this.accountsQuery.orderByDirection === 'desc' ? 'asc' : 'desc'
			}
		};
	}

	getOrderDirectionIcon(orderBy) {
		if (this.query.orderBy === orderBy) {
			return this.accountsQuery.orderByDirection === 'asc' ? down : up;
		} else {
			return '';
		}
	}

	render() {
		return html`
			<arc-account-list-filter></arc-account-list-filter>
			<div class="accountList">
				${this.accounts.length > 0
					? html`

						<div class="accountListHeader">
							<div></div>
							<div>${msg('Name')}</div>
							<div>${msg('eMail')}</div>
							<div>
								<arc-routerlink .route="admin-accounts" .query=${this.getOrderQuery('role')}>
									${msg('Role')} ${this.getOrderDirectionIcon('role')}
								</arc-routerlink>
							</div>
							<div>${msg('enabled')}</div>
							<div>${msg('test')}</div>
							<div>${msg('has logged in')}</div>
						</div>

						${repeat(
							this.accounts,
							(account) => account.id,
							(account, index) => html`
								<div class="accountListItem">
									<arc-account-list-item .account=${account}></arc-account-list-item>
								</div>`
						)}
						<arc-pagination .totalPages=${this.totalPages}></arc-pagination>
					`
					: html` <p class="emptyMessage">${msg('No accounts found.')}</p> `}
			</div>
		`;
	}
}
