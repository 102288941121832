import {LitElement, html, css} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import {connect} from 'pwa-helpers';
import {msg} from '@lit/localize';
import {when} from 'lit/directives/when.js';
import deepEqual from 'deep-equal';

import store from '../../store';
import style from '../../../../scss/cvSection.scss';
import {deepClone} from '../../util/deepClone';
import {selectCurrentContentLanguage, selectProfileDefaultLanguage} from '../../selectors';
import {getValueForLanguage} from '../../util/getValueForLanguage';

@customElement('arc-cv-section')
export class CvSection extends connect(store)(LitElement) {
	static properties = {
		index: {type: Number},
		editTitle: {type: Boolean},
		value: {type: Object, hasChanged: (n, o) => !deepEqual(n, o)},
		errors: {type: Array, hasChanged: (n, o) => !deepEqual(n, o)},
	};

	static styles = [style];

	inititalValue = {
		sectionName: [
			{value: '', lang: 'de'},
			{value: '', lang: 'en'},
		],
		entries: [],
	};

	constructor() {
		super();
		this.editTitle = true;
		store.subscribe(() => this.requestUpdate());
	}

	stateChanged(state) {
		this.lang = state.status.lang;
		this.contentLanguage = selectCurrentContentLanguage(state);
	}

	connectedCallback() {
		super.connectedCallback();
		if (!this.value) {
			this.value = deepClone(this.inititalValue);
		}
	}

	handleNameChange(value) {
		this.value.sectionName = value;
		this.dispatchValueChange();
		this.requestUpdate('value');
	}

	handleValueChange(value) {
		this.value.entries = value;
		this.dispatchValueChange();
		this.requestUpdate('value');
	}

	handleRemoveComponent() {
		this.dispatchEvent(
			new CustomEvent('remove-section', {
				detail: {
					index: this.index,
				},
			})
		);
	}

	dispatchValueChange() {
		this.dispatchEvent(
			new CustomEvent('value-change', {
				detail: {
					index: this.index,
					value: this.value,
				},
			})
		);
	}

	render() {
		return html`
			<div class="cvSection">
				<div class="sectionTitle">
					${when(
						this.editTitle,
						() => html` <arc-multilingual-input
							id="sectionName"
							class="formInput"
							.title="${msg('Section Name')}"
							.value=${this.value.sectionName}
							.errors=${this.errors?.params?.sectionName}
							.edit=${true}
							.required=${true}
							.multiline=${false}
							@value-change=${(e) => this.handleNameChange(e.detail.value)}
						></arc-multilingual-input>`,
						() => html`
							<h2>${getValueForLanguage(this.value.sectionName, this.contentLanguage, true)}</h2>
						`
					)}
					${when(
						this.value.entries.length === 0,
						() => html` <arc-button
							key=${this.index}
							title="${msg('Remove')}"
							type="secondary"
							class="removeBtn"
							@click=${this.handleRemoveComponent}
						></arc-button>`
					)}
				</div>
				<arc-form-repeat
					.components=${[
						{
							component: 'arc-cv-entry',
							type: null,
							label: msg('CV Entry'),
						},
					]}
					.data=${this.value.entries}
					.errors=${this.errors?.params?.entries?.params || []}
					@value-change=${(e) => this.handleValueChange(e.detail.value)}
				></arc-form-repeat>
			</div>
		`;
	}
}
