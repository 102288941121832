import {connect} from 'pwa-helpers';
import debounce from 'debounce';
import deepEqual from 'deep-equal';
import {LitElement, html} from 'lit';
import {navigator, router} from 'lit-element-router';
import {customElement} from 'lit/decorators/custom-element.js';
import {when} from 'lit/directives/when.js';

import {msg} from '@lit/localize';
import store from '../../store';
import style from '../../../../scss/artworkList.scss';
import {
	selectAllMaterials,
	selectAllArtworkTypes,
	setArtworkQuery,
	fetchMaterials,
	fetchArtworkTypes,
	selectAllSerie,
	fetchSeries,
	selectAllTags,
	fetchTags
} from '../../slices/artworkSlice';
import {
	selectArtworkQuery,
	selectProfileDefaultLanguage,
	selectFiltersWithCounters,
	selectLanguage
} from '../../selectors';
import {routeUrl} from '../../util/routeUrl';
import getRoutes from '../../routes';
import {trimObject} from '../../util/trimObject';
import {navigateTo} from '../../slices/statusSlice';

@customElement('arc-artwork-list-filter')
export class ArtworkListFilter extends connect(store)(router(navigator(LitElement))) {
	static styles = [style];

	static properties = {
		materials: {type: Array},
		searchText: {type: String},
		filtersVisible: {type: Boolean},
		tags: {type: Array},
		artworkQueryUpdateNeeded: {type: Boolean}
	};

	constructor() {
		super();
		this.currentLanguage = 'en';
		this.searchText = '';
		this.filtersVisible = false;
		this.artworkQueryUpdateNeeded = false;
		store.subscribe(() => this.requestUpdate());
	}

	stateChanged(state) {
		this.state = state;
		this.status = state.status;
		this.lang = selectLanguage(state);
		this.artworkQuery = selectArtworkQuery(state);
		this.materials = selectAllMaterials(state);
		this.artworkTypes = selectAllArtworkTypes(state);
		this.tags = selectAllTags(state);
		this.filtersWithCounters = selectFiltersWithCounters(state);
		this.currentLanguage = selectProfileDefaultLanguage(state);

		if (this.artworkQueryUpdateNeeded) {
			this.artworkQueryUpdateNeeded = false;
			store.dispatch(setArtworkQuery(this.query));
		}
	}

	static get routes() {
		return getRoutes();
	}

	router(route, params, query, data) {

		// do not update dispatch immediately
		this.artworkQueryUpdateNeeded = route === 'artworks' &&
			!deepEqual(this.query, query);

		this.params = params;
		this.query = query;
		this.data = data;
		this.profileId = params.id;

		// if query includes one of these keys, show filters
		// status,visibility,typeId,materialId,dateRangeBegin,dateRangeEnd,seriesId,tagIds
		this.filtersVisible =
			this.filtersVisible ||
			Object.keys(query).some((key) =>
				[
					'status',
					'visibility',
					'typeId',
					'materialId',
					'dateRangeBegin',
					'dateRangeEnd',
					'seriesId',
					'tagIds'
				].includes(key)
			);

		this.requestUpdate();
	}

	connectedCallback() {
		super.connectedCallback();

		if (selectAllMaterials(this.state).length === 0) {
			store.dispatch(fetchMaterials());
		}
		if (selectAllArtworkTypes(this.state).length === 0) {
			store.dispatch(fetchArtworkTypes());
		}
		if (selectAllSerie(this.state).length === 0) {
			store.dispatch(fetchSeries({profileId: this.profileId}));
		}
		if (selectAllTags(this.state).length === 0) {
			store.dispatch(fetchTags({profileId: this.profileId}));
		}
	}

	disconnectedCallback() {
		super.disconnectedCallback();
	}

	toggleFilters() {
		this.filtersVisible = !this.filtersVisible;
	}

	resetFilters() {
		this.navigateToQuery({});
	}

	handleSearchChange(value) {
		this.navigateToQuery({...this.query, ...{search: value.trim().toLowerCase()}});
	}

	handleStatusChange(value) {
		this.navigateToQuery({...this.query, ...{status: value}});
	}

	handleVisibilityChange(value) {
		this.navigateToQuery({...this.query, ...{visibility: value}});
	}

	handleMaterialChange(value) {
		this.navigateToQuery({...this.query, ...{materialId: value}});
	}

	handleTypeChange(value) {
		this.navigateToQuery({...this.query, ...{typeId: value}});
	}

	handleSeriesChange(value) {
		this.navigateToQuery({...this.query, ...{seriesId: value}});
	}

	handleCreationDateFromChange(value) {
		this.navigateToQuery({...this.query, ...{dateRangeBegin: value}});
	}

	handleCreationDateToChange(value) {
		this.navigateToQuery({...this.query, ...{dateRangeEnd: value}});
	}

	handleTagsChange(value) {
		this.navigateToQuery({...this.artworkQuery, tagIds: value});
	}

	navigateToQuery(query) {
		this.navigate(routeUrl('artworks', this.params, trimObject(query)));
		store.dispatch(setArtworkQuery(trimObject(query)));
	}

	render() {
		return html`
			<div class="artworkListFilter">
				<div class="searchAndFilters">
					<div class="search">
						<arc-text-input
							class="formInput search"
							.title="${msg('Search')}"
							.changeIndication=${false}
							.value=${this.artworkQuery.search}
							@value-change=${debounce((e) => this.handleSearchChange(e.detail.value), 500)}
						></arc-text-input>
					</div>
					<arc-button
						title="${this.filtersVisible ? msg('Hide Filters') : msg('More Filters')}"
						type="secondary"
						@click="${this.toggleFilters}"
					></arc-button>
					<arc-button
						title="${msg('Reset Filters')}"
						type="secondary"
						@click="${this.resetFilters}"
					></arc-button>
				</div>

				${when(
					this.filtersVisible,
					() => html`
						<div class="filterContainer">
							<div class="filterItem">
								<arc-multiselect
									class="formInput status"
									.singleSelect=${true}
									.title="${msg('Status')}"
									.edit=${true}
									.options=${this.filtersWithCounters.status}
									.value=${[this.artworkQuery.status]}
									@value-change=${(e) => this.handleStatusChange(e.detail.value[0])}
									.labelField=${'name'}
									.valueField=${'id'}
									.showSelectedCount=${false}
									.showCounters=${true}
									.emptyOption=${{label: msg('Select status...'), value: null}}
								></arc-multiselect>
							</div>

							<div class="filterItem">
								<arc-multiselect
									class="formInput visibility"
									.singleSelect=${true}
									.title="${msg('Visibility')}"
									.edit=${true}
									.options=${this.filtersWithCounters.visibility}
									.value=${[this.artworkQuery.visibility]}
									@value-change=${(e) => this.handleVisibilityChange(e.detail.value[0])}
									.labelField=${'name'}
									.valueField=${'id'}
									.showSelectedCount=${false}
									.showCounters=${true}
									.emptyOption=${{label: msg('Select visbility...'), value: null}}
								></arc-multiselect>
							</div>

							<div class="filterItem">
								<arc-multiselect
									class="formInput typeId"
									.singleSelect=${true}
									.title="${msg('Type')}"
									.edit=${true}
									.options=${this.filtersWithCounters.types}
									.value=${[this.artworkQuery.typeId]}
									@value-change=${(e) => this.handleTypeChange(e.detail.value[0])}
									.labelField=${'name'}
									.valueField=${'id'}
									.showSelectedCount=${false}
									.showCounters=${true}
									.emptyOption=${{label: msg('Select type...'), value: null}}
								></arc-multiselect>
							</div>
							<div class="filterItem">
								<arc-multiselect
									class="formInput materialId"
									.singleSelect=${true}
									.title="${msg('Material')}"
									.edit=${true}
									.options=${this.filtersWithCounters.materials}
									.value=${[this.artworkQuery.materialId]}
									@value-change=${(e) => this.handleMaterialChange(e.detail.value[0])}
									.labelField=${'name'}
									.valueField=${'id'}
									.showSelectedCount=${false}
									.showCounters=${true}
									.emptyOption=${{label: msg('Select material...'), value: null}}
								></arc-multiselect>
							</div>
							<div class="filterItem">
								<arc-date
									class="formInput dateRangeBegin"
									.title="${msg('Creation Date from')}"
									.granularity=${'year'}
									.changeIndication=${false}
									.value=${this.artworkQuery.dateRangeBegin}
									@value-change=${debounce(
										(e) => this.handleCreationDateFromChange(e.detail.value),
										500
									)}
								></arc-date>
							</div>
							<div class="filterItem">
								<arc-date
									class="formInput dateRangeEnd"
									.title="${msg('Creation Date to')}"
									.granularity=${'year'}
									.changeIndication=${false}
									.value=${this.artworkQuery.dateRangeEnd}
									@value-change=${debounce(
										(e) => this.handleCreationDateToChange(e.detail.value),
										500
									)}
								></arc-date>
							</div>
							<div class="filterItem">
								<arc-multiselect-tags-filter
									.title="${msg('Select Tags')}"
									class="formInput tags"
									.value=${this.artworkQuery.tagIds}
									.tags=${this.filtersWithCounters.tags}
									@value-change=${(e) => this.handleTagsChange(e.detail.value)}
								></arc-multiselect-tags-filter>
							</div>
							<div class="filterItem">
								<arc-select
									class="formInput materialId"
									.emptyOption=${''}
										.multiple=${false}
									.title="${msg('Series')}"
									.edit=${true}
									.changeIndication=${false}
									.options=${this.filtersWithCounters.series}
									.value=${this.artworkQuery.seriesId}
									@value-change=${(e) => this.handleSeriesChange(e.detail.value)}
								></arc-select>
							</div>
						</div>
					`
				)}
			</div>
		`;
	}
}
